import CampaignList from "../Components/CampaignList.vue";
import CampaignDetail from "../Pages/CampaignDetail.vue";

export default [
  {
    path: "campaign/",
    name: "campaignList",
    component: CampaignList,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "campaignList",
          disabled: true,
          label: "utils.campaignGestion",
        },
      ],
    },
  },
  {
    path: "campaign/:campaignId",
    name: "campaignDetail",
    component: CampaignDetail,
    props: true,
    meta: {
      authenticationRequired: true,
      breadcrumbs: [
        {
          name: "campaignList",
          disabled: false,
          label: "utils.campaignGestion",
        },
        {
          name: "campaignDetail",
          disabled: true,
          label: "utils.campaign",
        },
      ],
    },
  },
];
