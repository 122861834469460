<template>
  <div class="cd_container">
    <!-- HEADER TO DISPLAY CLIENT INFORMATION -->
    <ClientDetailHeader
      :client="activeClient"
      @createFolder="createFolderOpen = true"
    />

    <!-- BOARD DISPLAYING CLIENT PERSONAL DATA -->
    <ClientPersonalData />

    <!-- BOARD DISPLAYING CLIENT SHARED RECORDS DATA -->

    <ClientFolders
      v-if="!loading"
      :organization-id="$route.params.organizationId"
    />

    <!-- DIALOG TO DISPLAY DATATABLES -->
    <ClientShowAnswerDialog />
    <ClientCreateFolderDialog
      v-if="activeClient"
      :dialog-state="createFolderOpen"
      :client="activeClient"
      :organization-id="$route.params.organizationId"
      @close="createFolderOpen = false"
    />
  </div>
</template>

<script>
import ClientDetailHeader from "../Components/ClientDetailHeader.vue";
import ClientPersonalData from "../Components/ClientPersonalData.vue";
import ClientShowAnswerDialog from "../Components/ClientShowAnswerDialog.vue";
import ClientCreateFolderDialog from "../Components/ClientCreateFolderDialog.vue";
import { mapState } from "vuex";
import ClientFolders from "../Components/ClientFolders.vue";
import Folder from "@/core/Models/Folder";
import { findOrganizationFolders } from "@/modules/Folder/Services/folder.service";

export default {
  name: "ClientDetail",
  components: {
    ClientPersonalData,
    ClientDetailHeader,
    ClientShowAnswerDialog,
    ClientFolders,
    ClientCreateFolderDialog,
  },
  data() {
    return {
      loading: true,
      createFolderOpen: false,
      activeFolder: {
        loading: true,
        filters: {
          state: "OPEN",
          pageSize: 10,
          page: 1,
          ordering: "",
          q: "",
          name: "",
          status: [],
          assign: [],
          workflow: [],
          client: [],
        },
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          folders: [],
        },
      },
    };
  },
  computed: {
    ...mapState({
      activeClient: (state) => state.client.activeClient,
    }),
  },
  created() {
    this.initialize();
  },
  destroyed() {
    this.$store.commit("client/setActiveClient", null);
    this.$store.commit("client/setRecords", []);
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(
        "workflow/fetchWorkflows",
        this.$route.params.organizationId
      );
      await this.$store.dispatch("client/fetchActiveClient", {
        orgnizationId: this.$route.params.organizationId,
        clientId: this.$route.params.clientId,
      });
      this.loading = false;
      this.setBreadcrumbLabel();
    },
    async fetchActiveFolders() {
      this.activeFolder.loading = true;
      if (this.$route.query.status && this.$route.query.workflow) {
        this.activeFolder.filters.status.push(this.$route.query.status);
        this.activeFolder.filters.workflow.push(this.$route.query.workflow);
      }
      const res = await findOrganizationFolders({
        organizationId: this.organizationId,
        filters: this.activeFolder.filters,
      });
      this.activeFolder.detail = {
        ...res.data,
        folders: res.data.results.map((f) => {
          const res = new Folder(f);
          if (f.client) {
            res.client.firstName = f?.client?.firstname;
            res.client.lastName = f?.client?.lastname;
          }
          return res;
        }),
      };
      this.activeFolder.loading = false;
    },
    setBreadcrumbLabel() {
      if (this.activeClient.firstName || this.activeClient.lastName) {
        this.$route.meta.breadcrumbs[1].label = `${this.activeClient.firstName} ${this.activeClient.lastName}`;
      }
    },
  },
};
</script>
