import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";
export default {
  getOrganizationCampaigns({ organizationId, filters }) {
    const params = {};
    for (const key in filters) {
      if (Object.hasOwnProperty.call(filters, key)) {
        const filterValue = filters[key];
        if (
          (!filterValue && typeof filterValue !== "boolean") ||
          (Array.isArray(filterValue) && !filterValue.length)
        )
          continue;
        if (Array.isArray(filterValue)) {
          params[key] = filterValue.join("|");
        } else if (key == "pageSize") params.page_size = filterValue;
        else params[key] = filterValue;
      }
    }
    return cosmoAPI.get(`/organization/${organizationId}/campaign/`, {
      params: params,
    });
  },
  getOrganizationCampaingnServices(organizationId) {
    return cosmoAPI.get(`/wallet/organization/${organizationId}/campaign/`);
  },
  postCreateCopyCampaignAnonymousLink({
    organizationId,
    campaignId,
    settings,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/campaign/${campaignId}/link/`,
      {},
      settings
    );
  },
  postOrganizationCampaign({ organizationId, payload }) {
    return cosmoAPI.post(`/organization/${organizationId}/campaign/`, payload);
  },

  postRelaunchInvitationRecordDisagreedFromCampaign({
    organizationId,
    recordId,
    campaignId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/campaign/${campaignId}/records-disagreed/${recordId}/remind/`,
      payload
    );
  },
  postRelaunchInvitationRecordInProgressFromCampaign({
    organizationId,
    campaignId,
    recordId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/campaign/${campaignId}/records-in-progress/${recordId}/remind/`,
      payload
    );
  },
};
