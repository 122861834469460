<template>
  <div class="fl_container">
    <div class="tab_container tab_container_client">
      <div class="tab_header">
        <!-- Campaign Title -->
        <div class="tab_header_item" @click="menu.campaign = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.campaign" />
          </div>
          <div class="header_label">{{ $tc("campaign.campaign", 2) }}</div>
          <div v-if="menu.campaign" class="filter_menu">
            <div class="filter_menu_overlay" @click.prevent.stop="resetMenu" />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'name' }"
                @click.prevent.stop="changeOrderFilter('name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">{{ $tc("utils.ordering", 2) }}</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-name' }"
                @click.prevent.stop="changeOrderFilter('-name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">{{ $tc("utils.ordering", 1) }}</div>
              </div>
              <div class="filter_search_section">
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">{{ $t("utils.filter") }}</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="filters.name"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                    @keyup.enter="resetMenu"
                    @input="handleInputValue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Anonymous -->
        <div class="tab_header_item" @click="menu.anonymous = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.anonymous" />
          </div>
          <div class="header_label">{{ $t("editor.anonymous") }}</div>
          <div v-if="menu.anonymous" class="filter_menu" @click.stop>
            <div class="filter_menu_overlay" @click.prevent.stop="resetMenu" />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{
                  active_menu:
                    filters.course__anonymous == false &&
                    filters.course__anonymous !== '',
                }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'course__anonymous', value: false })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiAccount" />
                </div>
                <div class="item_label">{{ $tc("utils.isAnonymous", 1) }}</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.course__anonymous }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'course__anonymous', value: true })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiAccountOff" />
                </div>
                <div class="item_label">{{ $tc("utils.isAnonymous", 2) }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Course -->
        <div class="tab_header_item" @click="menu.course = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.course" />
          </div>
          <div class="header_label">{{ $tc("organization.course", 3) }}</div>
          <div v-if="menu.course" class="filter_menu">
            <div class="filter_menu_overlay" @click.prevent.stop="resetMenu" />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'course' }"
                @click.prevent.stop="changeOrderFilter('course')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">{{ $tc("utils.ordering", 2) }}</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-course' }"
                @click.prevent.stop="changeOrderFilter('-course')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">{{ $tc("utils.ordering", 1) }}</div>
              </div>
              <div class="filter_search_section">
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">{{ $t("utils.filter") }}</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="filters.course__name"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                    @keyup.enter="resetMenu"
                    @input="handleInputValue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- STATUS -->
        <div class="tab_header_item" @click="menu.status = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.status" />
          </div>
          <div class="header_label">{{ $tc("utils.status", 1) }}</div>
          <div v-if="menu.status" class="filter_menu">
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.status == 'DRAFT' }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'status', value: 'DRAFT' })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiCog" />
                </div>
                <div class="item_label">{{ $t("campaign.status.draft") }}</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.status == 'IN_PROGRESS' }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'status', value: 'IN_PROGRESS' })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiSend" />
                </div>
                <div class="item_label">
                  {{ $t("campaign.status.launched") }}
                </div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.status == 'CLOSED' }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'status', value: 'CLOSED' })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiLock" />
                </div>
                <div class="item_label">{{ $t("campaign.status.closed") }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Rappel automatique -->
        <div class="tab_header_item" @click="menu.autoReminder = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.autoReminder" />
          </div>
          <div class="header_label">{{ $t("course.autoReminder") }}</div>
          <div v-if="menu.autoReminder" class="filter_menu" @click.stop>
            <div class="filter_menu_overlay" @click.prevent.stop="resetMenu" />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.auto_reminder }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'auto_reminder', value: true })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiEmailCheckOutline" />
                </div>
                <div class="item_label">{{ $tc("utils.active", 1) }}</div>
              </div>
              <div
                class="menu_item"
                :class="{
                  active_menu:
                    filters.auto_reminder == false &&
                    filters.auto_reminder !== '',
                }"
                @click.prevent.stop="
                  updateFiltersValue({ key: 'auto_reminder', value: false })
                "
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiEmailRemoveOutline" />
                </div>
                <div class="item_label">{{ $tc("utils.active", 2) }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" class="progress_linear">
          <v-progress-linear indeterminate />
        </div>
      </div>
      <router-link
        v-for="(campaign, index) in campaignDetail.campaigns"
        :key="index"
        class="tab_row"
        :to="{
          name: 'campaignDetail',
          params: {
            organizationId: organizationId,
            campaignId: campaign.id,
          },
        }"
      >
        <div class="tab_col">
          <div class="folder_name">
            <div class="col_label" v-text="campaign.name" />
          </div>
        </div>
        <div class="tab_col">
          <template>
            <div
              class="col_label"
              v-text="
                campaign.course.anonymous
                  ? $tc('utils.isAnonymous', 2)
                  : $tc('utils.isAnonymous', 1)
              "
            />
          </template>
        </div>
        <div class="tab_col">
          <div class="col_label" v-text="campaign.course.name" />
        </div>
        <div class="tab_col">
          <div
            class="chips"
            :class="{
              active_chips: campaign.status === 'IN_PROGRESS',
              closed_chips: campaign.status === 'CLOSED',
            }"
          >
            {{ getCampaignStatus(campaign) }}
          </div>
        </div>
        <div class="tab_col">
          <div class="chips" :class="{ active_chips: campaign.autoReminder }">
            {{
              campaign.autoReminder
                ? $tc("utils.active", 1)
                : $tc("utils.active", 2)
            }}
          </div>
        </div>
      </router-link>
      <div v-if="!loading && !campaignDetail.campaigns.length" class="tab_row">
        <div class="no_data">{{ $tc("organization.campaign", 0) }}</div>
      </div>
    </div>
    <div
      v-if="campaignDetail.next > 0 || campaignDetail.previous > 0"
      class="pagination"
    >
      <v-pagination
        v-model="filters.page"
        :total-visible="filters.pageSize"
        :length="activeCampaignPages"
        @input="changeFilters(false)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CampaignListTable",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    campaignDetail: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      filters: {
        state: "OPEN",
        pageSize: 10,
        page: 1,
        ordering: "",
        name: "",
        status: "",
      },
      queryString: "",
      menu: {
        campaign: false,
        anonymous: false,
        course: false,
        status: false,
        autoReminder: false,
      },
    };
  },

  computed: {
    activeCampaignPages() {
      if (!this.campaignDetail.count) return 0;
      return Math.ceil(this.campaignDetail.count / this.filters.pageSize);
    },
    headerIcons() {
      const icons = {};
      icons.campaign =
        this.filters.ordering == "name" ? "$mdiMenuUp" : "$mdiMenuDown";
      icons.anonymous = "$mdiMenuDown";
      icons.course =
        this.filters.ordering == "course " ? "$mdiMenuUp" : "$mdiMenuDown";
      icons.status = "$mdiMenuDown";
      icons.autoReminder = "$mdiMenuDown";
      return icons;
    },
    ...mapState({
      workflows: (state) => state.workflow.workflows,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
  },

  watch: {
    modelValue: {
      handler(value) {
        this.filters = value;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // filter
    updateFiltersValue({ value, key }) {
      this.$emit("update-filter-value", {
        filterKey: key,
        value: value,
      });
      this.handleInputValue();
    },
    // space
    getCampaignStatus(campaign) {
      switch (campaign.status) {
        case "DRAFT":
          return this.$t("campaign.status.draft");
        case "IN_PROGRESS":
          return this.$t("campaign.status.launched");
        case "CLOSED":
          return this.$t("campaign.status.closed");
        default:
          return "-";
      }
    },
    resetMenu() {
      this.menu = {
        campaign: false,
        anonymous: false,
        course: false,
        status: false,
        autoReminder: false,
      };
      this.queryString = "";
    },
    changeOrderFilter(value) {
      this.filters.ordering = value;
      this.resetMenu();
      this.changeFilters();
    },
    handleInputValue(resetPage = true) {
      if (resetPage) this.filters.page = 1;
      this.$emit("inputValue", this.filters);
    },
    changeFilters(resetPage = true) {
      if (resetPage) this.filters.page = 1;
      this.$emit("changeValue", this.filters);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_container_client {
  .tab_header_item {
    width: calc(100% / 5) !important;
  }
  .tab_col {
    width: calc(100% / 5) !important;
  }
}

.tab_container {
  background: #fcfcfc;
  box-shadow: 0px 5px 15px 0px rgba(20, 20, 243, 0.1),
    0px 2px 2px 0px rgba(0, 15, 144, 0.2);
  .tab_header {
    display: flex;
    position: relative;
    .tab_header_item {
      flex: none;
      background: #fcfcfc;
      width: calc(100% / 6);
      padding: 16px 32px;
      background: #fff;
      transition: all 0.25s;
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #d0dffd;
      }
      .header_icon {
        background: #d0dffd;
        flex: none;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
          }
        }
      }
      .header_label {
        color: #353542;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .filter_menu {
        position: absolute;
        z-index: 10;
        top: 80%;
        right: 0;
        z-index: 10000;

        .filter_menu_overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1;
          background: transparent;
        }
        .filter_menu_content {
          position: relative;
          z-index: 2;
          width: 250px;
          padding: 8px 0;
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 5px 15px 0px rgba(20, 20, 243, 0.1);
          .menu_item {
            display: flex;
            align-items: center;
            padding: 10px 16px;
            border-bottom: 1px solid #cfcfdc;
            transition: all 0.25s;
            &:last-child {
              border-bottom: none;
            }
            .item_icon {
              margin-right: 4px;
              height: 16px;
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              &::v-deep {
                .v-icon {
                  height: 16px;
                  width: 16px;
                  color: #353542;
                }
              }
            }
            .item_label {
              color: #353542;
              font-family: "Inter";
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
            &.active_menu {
              .item_icon {
                &::v-deep {
                  .v-icon {
                    color: #2536cc;
                  }
                }
              }
              .item_label {
                color: #2536cc;
              }
            }
            &:hover {
              .item_icon {
                &::v-deep {
                  .v-icon {
                    color: #2536cc;
                  }
                }
              }
              .item_label {
                color: #2536cc;
              }
            }
          }
          .filter_search_section {
            padding: 10px 16px;
            position: relative;
            .progression {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
            }
            .search_header {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .filter_icon {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
                &::v-deep {
                  .v-icon {
                    color: #484964;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .header_label {
                color: #484964;
                font-family: "Inter";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }
            }
            .search_input {
              width: 100%;
              .input {
                color: #353542;
                font-family: "Inter";
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                padding: 6px 16px;
                border: 1px solid #000;
                border-radius: 5px;
                width: 100%;
                &:focus {
                  border: 1px solid #2536cc;
                  outline: 3px solid #ebf2ff;
                }
              }
              &::v-deep {
                .v-input__append-inner {
                  display: none;
                }

                .v-input {
                  color: #353542;
                  font-family: "Inter";
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 500;
                  &.v-input--is-focused {
                    outline: 3px solid #ebf2ff;
                  }
                }
              }
              .selected_item {
                border-radius: 36px;
                background: #fff;
                display: flex;
                align-items: center;
                max-width: 100%;
                .item_label {
                  color: #353542;
                  font-family: "Inter";
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 500;
                  width: calc(100% - 22px);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .close_icon {
                  width: 18px;
                  height: 18px;
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  &::v-deep {
                    .v-icon {
                      height: 18px;
                      width: 18px;
                      color: rgba(0, 0, 0, 0.6);
                    }
                  }
                }
              }
            }
            .search_items {
              width: 100%;
              max-height: 176px;
              overflow: auto;
              &::-webkit-scrollbar {
                display: none;
              }

              .search_item {
                width: 100%;
                margin-bottom: 16px;
                &:last-child {
                  margin-bottom: 0;
                }
                .check_label {
                  width: 186px;
                  text-wrap: wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #353542;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
    .progress_linear {
      position: absolute;
      width: 100%;
      bottom: -4px;
      left: 0;
      z-index: 2;
    }
  }
  .tab_row {
    display: flex;
    background: #fcfcfc;
    border-top: 1px solid #c4c4c4;
    transition: all 0.25s;
    cursor: pointer;
    text-decoration: none;
    .tab_col {
      flex: none;
      padding: 16px;
      width: calc(100% / 6);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      .col_label {
        color: #353542;
        font-family: "Inter";
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: wrap;
        &.label_secondary {
          color: #707080;
        }
      }
      .chips {
        margin-top: 2px;
      }
      .folder_name {
        display: flex;
        align-items: center;

        .bagde {
          margin-right: 4px;
        }
      }
    }

    .no_data {
      display: flex;
      justify-content: center;
      padding: 20px 16px;
      color: #353542;
      font-family: "Inter";
      font-size: 12px;
      line-height: 16px;
      font-style: normal;
      font-weight: 400;
      width: 100%;
    }
    &:hover {
      background: #e5e5e5;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
}
.chips {
  border-radius: 5px;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #ff6b00;
  background-color: #fff3f0;
}
.active_chips {
  color: #18a300;
  background-color: #f0ffd6;
}
.closed_chips {
  color: #2536cc;
  background-color: #f4f7ff;
}
</style>
