import Vue from "vue/dist/vue.esm.js";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import coreRoutes from "@/core/Routes";
import clientRoutes from "@/modules/Client/Routes";
import folderRoutes from "@/modules/Folder/Routes";
import editorRoutes from "@/modules/Editor/Routes";
import walletRoutes from "@/modules/Wallet/Routes";
import campaignRoutes from "@/modules/Campaign/Routes";
import CosmoformRoutes from "@/modules/Cosmoform/Routes";
import ClientFullData from "@/modules/ClientFullData/Routes";

import adminRoutes from "@/modules/Admin/Routes";

import { DashboardRoute } from "@/modules/Authentication/Routes";
import Organization from "@/core/Pages/Organization";
import Oauth from "@/core/Models/Oauth";
import qs from "qs";

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
  },
  ...DashboardRoute,
  ...walletRoutes,
  ...CosmoformRoutes,
  ...ClientFullData,
  {
    path: "/organization",
    name: "organizationList",
    component: Organization,
    meta: {
      authenticationRequired: true,
    },
  },
  {
    path: "/organization/:organizationId",
    name: "organization",
    component: Organization,
    props: true,
    children: [
      ...coreRoutes,
      ...clientRoutes,
      ...folderRoutes,
      ...editorRoutes,
      ...adminRoutes,
      ...campaignRoutes,
    ],
  },
];

export const router = new VueRouter({
  mode: "history",
  base: "",
  parseQuery(query) {
    return qs.parse(query, {
      decoder: (str) => {
        return decodeURIComponent(str);
      },
    });
  },
  stringifyQuery: (query) => {
    const result = qs.stringify(query, {
      encoder: (str) => {
        return encodeURIComponent(str);
      },
    });
    return result ? `?${result}` : "";
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // catch 404 and send to back 404.html
  if (to.name === "NotFound") return location.replace("/404");

  // catching redundant navigation to same path route
  const lastPath = from.fullPath;
  const goPath = to.fullPath;
  if (lastPath === goPath) return;
  // catching guarded route
  if (to.matched.some((record) => record.meta.authenticationRequired)) {
    if (!Oauth.localStorage) {
      return next({
        name:
          to.name === "WalletCourse" || to.name === "WalletSafe"
            ? "Register"
            : "Login",
        query: { next: `${to.fullPath}` },
      });
    }
    next();
  } else next();
});
