import {
  findOrganizationCourses,
  findOrganizationCourse,
  findOrganizationCourseCampaigns,
  fetchCourseStep,
  createCourseStep,
  patchCourseStepQuestion,
  editCourseStep,
  deleteCourseStep,
  createCourseStepQuestion,
  deleteOrganizationCourse,
  deleteCourseStepQuestion,
  duplicateOrganizationCourse,
  editCourseStepQuestion,
  editLogicalJumps,
  createOrganizationCourse,
  editOrganizationCourse,
  editOrganizationCourseImage,
  moveQuestion,
  moveStep,
  postQuestionGroup,
  deleteQuestionGroup,
  toggleOrganizationCourseArchived,
  fetchOrganizationCourseCampaign,
  patchOrganizationCourseCampaign,
  deleteOrganizationCourseCampaign,
  postOrganizationCourseCampaignInvite,
  fetchOrganizationCourseCampaignRecordsAgreed,
  fetchOrganizationCourseCampaignRecordsDisagreed,
  fetchOrganizationCourseCampaignRecordsInProgress,
  moveQuestionDrop,
} from "../Services/course.service";

import Course from "@/core/Models/Course";
import Campaign from "@/core/Models/Campaign";
import Step from "@/core/Models/Step";
import Question from "@/core/Models/Question";
import {
  getOrganizationCampaignsServices,
  postOrganizationCampaign,
} from "../Services/campaign.service";

export default {
  namespaced: true,
  state: {
    // List Page
    activeCoursesAmount: 0,
    activeCourses: [],
    activeCoursesPromise: null,
    archivedCoursesAmount: 0,
    archivedCourses: [],
    archivedCoursesPromise: null,
    displayActiveCourses: true,
    // Detail page
    recordToRelaunch: null,
    course: null,
    coursePromise: null,
    campaigns: [],
    campaignsPromise: null,
    activeCampaign: null,
    isFetchingCourseCampaign: true,
    activeStep: null,
    // course question
    activeQuestion: null,
    organizationCampaignsServices: [],
    questionsAvailableToGroup: [],
    modals: {
      isCampaignCreateAndEditDialogOpen: false,
      isCampaignDeleteDialogOpen: false,
      isCampaignInviteDialogOpen: false,
    },
  },
  mutations: {
    //#region LISTS
    SET_RECORD_TO_RELAUNCH(state, payload) {
      state.recordToRelaunch = payload;
    },
    SET_ACTIVE_COURSES(state, payload) {
      state.activeCourses = payload
        ? payload.map((course) => new Course(course))
        : [];
    },
    SET_ACTIVE_COURSES_PROMISE(state, payload) {
      state.activeCoursesPromise = payload;
    },
    SET_ARCHIVED_COURSES(state, payload) {
      state.archivedCourses = payload
        ? payload.map((course) => new Course(course))
        : [];
    },
    SET_ARCHIVED_COURSES_PROMISE(state, payload) {
      state.archivedCoursesPromise = payload;
    },
    setDisplayActiveCourses(state, payload) {
      state.displayActiveCourses = payload;
    },
    setActiveCoursesAmount(state, payload) {
      state.activeCoursesAmount = payload;
    },
    setArchivedCoursesAmount(state, payload) {
      state.archivedCoursesAmount = payload;
    },
    //#endregion
    //#region ROOT VARIABLES MANAGEMENT
    SET_COURSE(state, payload) {
      state.course = payload ? new Course(payload) : null;
    },
    SET_COURSE_PROMISE(state, payload) {
      state.coursePromise = payload;
    },
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload
        ? payload.map((campaign) => new Campaign(campaign))
        : [];
    },
    SET_CAMPAIGNS_PROMISE(state, payload) {
      state.campaignsPromise = payload;
    },
    SET_ACTIVE_STEP(state, payload) {
      state.activeStep = payload ? new Step(payload) : null;
    },
    SET_ACTIVE_QUESTION(state, payload) {
      state.activeQuestion = payload ? new Question(payload) : null;
    },
    UPDATE_QUESTION_JUMPS(state, { stepId, questionId, logicalJumps }) {
      if (!state?.course?.steps?.length) return;
      const questionStep = state.course.steps.find(
        (step) => step.id === stepId
      );
      const question = questionStep.questions.find((q) => q.id == questionId);
      question.logicjumps = logicalJumps.filter((j) => !isNaN(j.questionId));
    },
    //#endregion
    //#region COURSE MANAGEMENT
    SET_COURSE_IMAGE(state, payload) {
      state.course.image = payload;
    },
    //#endregion
    //#region STEPS MANAGEMENT
    CREATE_STEP(state, payload) {
      payload = payload ? new Step(payload) : null;
      state.course.steps.push(payload);
    },
    EDIT_STEP(state, payload) {
      state.course.steps.splice(
        state.course.steps.findIndex((step) => step.id === payload.id),
        1,
        payload ? new Step(payload) : null
      );
    },
    DELETE_STEP(state, stepId) {
      state.course.steps = state.course.steps.filter(
        (step) => step.id !== stepId
      );
    },
    //#endregion
    //#region QUESTIONS MANAGEMENT
    ADD_QUESTION_TO_STEP(state, { stepId, payload }) {
      let step = state.course.steps.find((s) => s.id == stepId);
      step.questions.push(payload ? new Question(payload) : null);
      state.course.steps.splice(
        state.course.steps.findIndex((s) => s.id == stepId),
        1,
        new Step(step)
      );
    },
    reOrderQuestionSteps(state, { stepId, payload }) {
      let step = state.course.steps.find((s) => s.id === stepId);
      step.questions = payload
        ? payload.map((question) => new Question(question))
        : null;
      state.course.steps.splice(
        state.course.steps.findIndex((s) => s.id == stepId),
        1,
        new Step(step)
      );
    },
    setAvailableQuestionToGroup(state, payload) {
      state.questionsAvailableToGroup = [...payload.sort((a, b) => a - b)];
    },
    EDIT_STEP_QUESTION(state, { stepId, editedQuestion }) {
      let step = state.course.steps.find((s) => s.id == stepId);
      step.questions.splice(
        step.questions.findIndex(
          (question) => question.id === editedQuestion.id
        ),
        1,
        editedQuestion ? new Question(editedQuestion) : null
      );
      state.course.steps.splice(
        state.course.steps.findIndex((s) => s.id == stepId),
        1,
        new Step(step)
      );
    },
    DELETE_QUESTION(state, { stepId, questionId }) {
      state.course.steps.map((step) => {
        if (step.id === stepId) {
          step.questions = step.questions.filter(
            (question) => question.id !== questionId
          );
        }
        return new Step(step);
      });
    },
    //#endregion
    //#region CAMPAIGNS MANAGEMENT
    setActiveCampaign(state, payload) {
      state.activeCampaign = payload ? new Campaign(payload) : null;
    },
    setCampaignServices(state, payload) {
      state.organizationCampaignsServices = [...payload];
    },
    setIsFetchingCourseCampaign(state, payload) {
      state.isFetchingCourseCampaign = payload;
    },
    //#endregion
    //#region MODALS
    setCampaignCreateAndEditDialog(state, payload) {
      state.modals.isCampaignCreateAndEditDialogOpen = payload;
    },
    setCampaignDeleteDialog(state, payload) {
      state.modals.isCampaignDeleteDialogOpen = payload;
    },
    setCampaignInviteDialog(state, payload) {
      state.modals.isCampaignInviteDialogOpen = payload;
    },
    //#endregion
  },
  actions: {
    //#region INITIALIZATION LISTS
    async fetchCourses(
      { commit, state, rootState },
      { organizationId, archived }
    ) {
      /**
       * If archived is true then we are fetching the archived courses instead
       * of the active courses.
       */
      const dataMutation = archived
        ? "SET_ARCHIVED_COURSES"
        : "SET_ACTIVE_COURSES";
      const dataPromiseMutation = archived
        ? "SET_ARCHIVED_COURSES_PROMISE"
        : "SET_ACTIVE_COURSES_PROMISE";
      // Create recursive function fetching all pages of courses
      async function fetchCoursesPage(page) {
        // Call API endpoint filtering by is archived and by page
        const res = await findOrganizationCourses(
          organizationId,
          archived,
          page
        );
        const coursesFetched = res.data.results;
        /**
         * If we are fetching the first page of the active courses then
         * we can get the amount of active courses. We don't need to do it for
         * each page
         */
        if (!archived && page === 1) {
          commit("setActiveCoursesAmount", res.data.count);
        }
        // If we didn't changed organization while fetching was loading actual page
        if (rootState.organization.activeOrganization.id !== +organizationId) {
          return;
        }
        if (archived) {
          commit(dataMutation, [...state.archivedCourses, ...coursesFetched]);
        } else {
          commit(dataMutation, [...state.activeCourses, ...coursesFetched]);
        }
        // Fetch next page if it exists
        if (res.data.next) {
          fetchCoursesPage(res.data.next);
        }
        commit(dataPromiseMutation, null);
        return coursesFetched;
      }

      if (archived) {
        if (state.archivedCourses.length > 0) {
          return true;
        }
        if (state.archivedCoursesPromise) {
          return state.archivedCoursesPromise;
        }
      } else {
        if (state.activeCourses.length > 0) {
          return true;
        }
        if (state.activeCoursesPromise) {
          return state.activeCoursesPromise;
        }
      }
      // Reset list to it's default value because we will fetch first page
      commit(dataMutation, []);
      // Fetch first page of data
      const promise = fetchCoursesPage(1);
      commit(dataPromiseMutation, promise);
      const res = await promise;
      commit(dataPromiseMutation, null);
      return res;
    },
    async fetchArchivedCoursesAmount({ commit }, organizationId) {
      const res = await findOrganizationCourses(organizationId, true, 1);
      commit("setArchivedCoursesAmount", res.data.count);
    },
    resetActiveCourses({ commit }) {
      commit("SET_ACTIVE_COURSES", []);
    },
    resetArchivedCourses({ commit }) {
      commit("SET_ARCHIVED_COURSES", []);
    },
    //#endregion
    //#region INITIALIZATION
    // Fetch active course from API
    async fetchCourse({ state, commit }, { organizationId, courseId }) {
      if (state.course) {
        return true;
      }
      if (state.coursePromise) {
        return state.coursePromise;
      }
      const promise = findOrganizationCourse(organizationId, courseId);
      commit("SET_COURSE_PROMISE", promise);
      try {
        const res = await promise;
        commit("SET_COURSE", res);
        commit("SET_COURSE_PROMISE", null);
        return res;
      } catch (error) {
        commit("SET_COURSE", null);
        throw error;
      }
    },
    // Reset Active course
    resetCourse({ commit }) {
      commit("SET_COURSE", null);
    },
    // Fetch active course campaigns from API
    async fetchCampaigns({ state, commit }, { organizationId }) {
      commit("setIsFetchingCourseCampaign", true);
      if (state.campaigns.length) {
        return state.campaigns;
      }
      if (state.campaignsPromise) {
        return await state.campaignsPromise;
      }
      const promise = findOrganizationCourseCampaigns(organizationId);
      commit("SET_CAMPAIGNS_PROMISE", promise);
      try {
        const res = await promise;
        const campaigns = res.data.results;

        commit("SET_CAMPAIGNS", campaigns);
        commit("SET_CAMPAIGNS_PROMISE", null);
        commit("setIsFetchingCourseCampaign", false);
        return campaigns;
      } catch (error) {
        commit("SET_CAMPAIGNS", null);
        throw error;
      }
    },
    // Reset Active course campaigns
    resetCampaigns({ commit }) {
      commit("SET_CAMPAIGNS", null);
    },
    //#endregion
    //#region ROOT VARIABLES
    setActiveStep({ commit }, payload) {
      commit("SET_ACTIVE_STEP", payload);
    },
    setActiveQuestion({ commit }, payload) {
      commit("SET_ACTIVE_QUESTION", payload);
    },
    //#endregion
    //#region ACTIVE COURSE CRUD
    async createCourse({ state, commit }, { organizationId, payload }) {
      const course = await createOrganizationCourse(organizationId, payload);
      commit("SET_ACTIVE_COURSES", [...state.activeCourses, course]);
      return course;
    },
    async editCourse({ state, commit }, { organizationId, courseId, payload }) {
      const res = await editOrganizationCourse(
        organizationId,
        courseId,
        payload
      );
      commit("SET_COURSE", {
        ...state.course,
        ...res,
      });
    },
    async duplicateCourse(_, { organizationId, courseId, payload }) {
      return await duplicateOrganizationCourse(
        organizationId,
        courseId,
        payload
      );
    },
    async deleteCourse(_, { organizationId, courseId }) {
      await deleteOrganizationCourse(organizationId, courseId);
    },
    async editCourseImage({ commit }, { organizationId, courseId, image }) {
      if (image.name.length > 100) {
        let strs = image.name.split(".");
        const extension = "." + strs.splice(strs.length - 1, 1)[0];
        const name =
          strs.join(".").substr(0, 99 - extension.length) + extension;
        image = new File([image], name, { type: image.type });
      }
      const course = await editOrganizationCourseImage(
        organizationId,
        courseId,
        image
      );
      commit("SET_COURSE_IMAGE", course.image);
      return course;
    },
    //#endregion
    //#region STEPS CRUD
    async fetchStep({ commit }, { organizationId, courseId, stepId }) {
      const res = await fetchCourseStep(organizationId, courseId, stepId);
      commit("EDIT_STEP", res.data);
      return res.data;
    },
    async createStep({ commit }, { organizationId, courseId, payload }) {
      const newStep = await createCourseStep(organizationId, courseId, payload);
      commit("CREATE_STEP", newStep);
      return newStep;
    },
    async editStep({ commit }, { organizationId, courseId, payload }) {
      const step = await editCourseStep(organizationId, courseId, payload);
      commit("EDIT_STEP", step);
      return step;
    },
    async deleteStep({ commit }, { organizationId, courseId, stepId }) {
      await deleteCourseStep(organizationId, courseId, stepId);
      commit("DELETE_STEP", stepId);
    },
    async moveStep({ commit }, { organizationId, courseId, stepId, goUp }) {
      const course = await moveStep(organizationId, courseId, stepId, goUp);
      commit("SET_COURSE", course);
      return course;
    },
    //#endregion
    //#region QUESTIONS CRUD
    async createQuestion(
      { commit },
      { organizationId, courseId, stepId, payload }
    ) {
      const question = await createCourseStepQuestion(
        organizationId,
        courseId,
        stepId,
        payload
      );
      commit("ADD_QUESTION_TO_STEP", {
        stepId,
        payload: question,
      });
      return question;
    },
    async moveQuestionOrder(
      { commit },
      {
        organizationId,
        courseId,
        stepId,
        questionId,
        newPosition,
        isGroup = true,
      }
    ) {
      const response = await moveQuestionDrop({
        organizationId,
        courseId,
        stepId,
        questionId,
        newPosition,
        isGroup,
      });
      if (isGroup) {
        // if group execute series of request before updating the step
        commit("reOrderQuestionSteps", {
          stepId: stepId,
          payload: [...response],
        });
      }
    },
    async patchQuestion(
      { commit },
      { organizationId, courseId, stepId, questionId, question }
    ) {
      const res = await patchCourseStepQuestion(
        organizationId,
        courseId,
        stepId,
        questionId,
        question
      );
      commit("EDIT_STEP_QUESTION", {
        stepId,
        editedQuestion: res.data,
      });
      return res.data;
    },
    async editQuestion(
      { commit },
      {
        organizationId,
        courseId,
        stepId,
        questionId,
        question,
        deletedOptionsIds,
        isOptionChanged,
      }
    ) {
      const editedQuestion = await editCourseStepQuestion(
        organizationId,
        courseId,
        stepId,
        questionId,
        question,
        deletedOptionsIds,
        isOptionChanged
      );
      commit("EDIT_STEP_QUESTION", {
        stepId,
        editedQuestion,
      });
      return editedQuestion;
    },
    async deleteQuestion(
      { commit },
      { organizationId, courseId, stepId, questionId }
    ) {
      await deleteCourseStepQuestion(
        organizationId,
        courseId,
        stepId,
        questionId
      );
      commit("DELETE_QUESTION", { stepId, questionId });
    },
    async moveQuestion(
      { commit },
      { organizationId, courseId, stepId, questionId, goUp }
    ) {
      const step = await moveQuestion(
        organizationId,
        courseId,
        stepId,
        questionId,
        goUp
      );
      commit("EDIT_STEP", step);
      return step;
    },
    async groupQuestions(_, { organizationId, courseId, stepId, label }) {
      const res = await postQuestionGroup(
        organizationId,
        courseId,
        stepId,
        label
      );
      return res;
    },
    async deleteGroup(_, { organizationId, courseId, stepId, groupId }) {
      const res = await deleteQuestionGroup(
        organizationId,
        courseId,
        stepId,
        groupId
      );
      return res;
    },
    //#endregion
    //#region LOGICAL JUMP CRUD
    async editLogicalJumps(
      { commit },
      { organizationId, courseId, stepId, questionId, logicalJumps }
    ) {
      await editLogicalJumps(
        organizationId,
        courseId,
        stepId,
        questionId,
        logicalJumps
      );
      commit("UPDATE_QUESTION_JUMPS", { stepId, questionId, logicalJumps });
    },
    //#endregion
    //#region ARCHIVE COURSE
    async courseToggleArchive(
      { state, commit, dispatch },
      { organizationId, courseId, actualStateIsArchived }
    ) {
      const res = await toggleOrganizationCourseArchived(
        organizationId,
        courseId,
        !actualStateIsArchived
      );
      const course = res.data;
      // Set active course value
      if (state.course) {
        commit("SET_COURSE", {
          ...state.course,
          archived: course.archived,
        });
        dispatch("resetCampaigns");
        dispatch("fetchCampaigns", {
          organizationId,
          courseId,
        });
      }
      if (course.archived) {
        commit(
          "SET_ACTIVE_COURSES",
          state.activeCourses.filter((c) => c.id !== course.id)
        );
        commit("setActiveCoursesAmount", state.activeCoursesAmount - 1);
        commit("setArchivedCoursesAmount", state.archivedCoursesAmount + 1);
        if (state.archivedCourses.length > 0) {
          commit(
            "SET_ARCHIVED_COURSES",
            [...state.archivedCourses, course].sort(
              (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
            )
          );
        }
      } else {
        commit("setActiveCoursesAmount", state.activeCoursesAmount + 1);
        commit("setArchivedCoursesAmount", state.archivedCoursesAmount - 1);
        commit(
          "SET_ACTIVE_COURSES",
          [...state.activeCourses, course].sort(
            (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
          )
        );
        commit(
          "SET_ARCHIVED_COURSES",
          state.archivedCourses.filter((c) => c.id !== course.id)
        );
      }
    },
    //#endregion
    //#region Campaign
    async getOrganizationCampaignsServices({ commit }, { organizationId }) {
      const res = await getOrganizationCampaignsServices(organizationId);
      commit("setCampaignServices", res.data.results);
    },
    async createOrganizationCampaign(
      { state, commit },
      { organizationId, payload }
    ) {
      const res = await postOrganizationCampaign({ organizationId, payload });
      const campaign = res.data;
      commit("SET_CAMPAIGNS", [...state.campaigns, campaign]);
      return campaign;
    },
    async fetchActiveCampaign({ commit }, { organizationId, campaignId }) {
      const res = await fetchOrganizationCourseCampaign({
        organizationId,
        campaignId,
      });
      const campaign = res.data;
      commit("setActiveCampaign", campaign);
      return campaign;
    },
    async updateOrganizationCourseCampaign(
      { commit, state },
      { organizationId, campaignId, payload }
    ) {
      const res = await patchOrganizationCourseCampaign({
        organizationId,
        campaignId,
        payload,
      });
      commit("setActiveCampaign", { ...state.activeCampaign, ...res.data });
      return res.data;
    },
    async deleteOrganizationCourseCampaign(_, { organizationId, campaignId }) {
      const res = await deleteOrganizationCourseCampaign({
        organizationId,
        campaignId,
      });
      return res;
    },
    async postOrganizationCourseCampaignInvite(
      { state, commit, dispatch },
      { organizationId, campaignId, payload }
    ) {
      const res = await postOrganizationCourseCampaignInvite({
        organizationId,
        campaignId,
        payload,
      });
      commit("setActiveCampaign", {
        ...state.activeCampaign,
        recordsInProgress: [],
      });
      dispatch("fetchOrganizationCourseCampaignRecordsInProgress", {
        organizationId,
        campaignId,
      });
      return res;
    },
    //#endregion
    //#region Campaign records
    async fetchOrganizationCourseCampaignRecordsAgreed(
      { state, commit, rootState },
      { organizationId, campaignId }
    ) {
      async function fetchRecordsPage(page) {
        const res = await fetchOrganizationCourseCampaignRecordsAgreed({
          organizationId,
          campaignId,
          page,
        });
        if (
          rootState.organization.activeOrganization.id !==
          Number.parseInt(organizationId)
        ) {
          return;
        }
        const records = res.data.results.map((record) => ({
          ...record,
          nextReminderDate: state.activeCampaign?.nextReminderDate,
          steps: state.course?.steps
            ? state.course?.steps?.map((step) => ({
                ...step,
                questions: step.questions.map((question) => ({
                  ...question,
                  answer: record.answers.find(
                    (answer) => answer.question === question.id
                  ),
                })),
              }))
            : [],
        }));

        if (page === 1) {
          commit("setActiveCampaign", {
            ...state.activeCampaign,
            recordsAgreed: records,
          });
        } else {
          commit("setActiveCampaign", {
            recordsAgreed: state.activeCampaign?.records
              ? [...state.activeCampaign?.records, ...records]
              : [...records],
          });
        }
        if (res.data.next) {
          fetchRecordsPage(res.data.next);
        }
        return records;
      }
      return await fetchRecordsPage(1);
    },
    async fetchOrganizationCourseCampaignRecordsDisagreed(
      { state, commit, rootState },
      { organizationId, campaignId }
    ) {
      async function fetchRecordsPage(page) {
        const res = await fetchOrganizationCourseCampaignRecordsDisagreed({
          organizationId,
          campaignId,
          page,
        });
        if (
          rootState.organization.activeOrganization.id !==
          Number.parseInt(organizationId)
        ) {
          return;
        }

        const records = res.data.results.map((record) => ({
          ...record,
          nextReminderDate: state.activeCampaign?.nextReminderDate,
          steps: state.course?.steps
            ? state.course?.steps?.map((step) => ({
                ...step,
                questions: step.questions.map((question) => ({
                  ...question,
                  answer: record.answers.find(
                    (answer) => answer.question === question.id
                  ),
                })),
              }))
            : [],
        }));
        if (page === 1) {
          commit("setActiveCampaign", {
            ...state.activeCampaign,
            recordsDisagreed: records,
          });
        } else {
          commit("setActiveCampaign", {
            recordsAgreed: state.activeCampaign?.records
              ? [...state.activeCampaign?.records, ...records]
              : [...records],
          });
        }
        if (res.data.next) {
          fetchRecordsPage(res.data.next);
          return records;
        }
      }
      return await fetchRecordsPage(1);
    },
    async fetchOrganizationCourseCampaignRecordsInProgress(
      { state, commit, rootState },
      { organizationId, campaignId }
    ) {
      async function fetchRecordsPage(page) {
        const res = await fetchOrganizationCourseCampaignRecordsInProgress({
          organizationId,
          campaignId,
          page,
        });
        if (
          rootState.organization.activeOrganization.id !==
          Number.parseInt(organizationId)
        ) {
          return;
        }
        const records = res.data.results.map((record) => {
          commit("SET_RECORD_TO_RELAUNCH", { ...record, course: state.course });
          return {
            ...record,
            nextReminderDate: state.activeCampaign?.nextReminderDate,
            steps: state.course?.steps
              ? state.course?.steps?.map((step) => ({
                  ...step,
                  questions: step.questions.map((question) => ({
                    ...question,
                    answer: record.answers.find(
                      (answer) => answer.question === question.id
                    ),
                  })),
                }))
              : [],
          };
        });
        if (page === 1) {
          commit("setActiveCampaign", {
            ...state.activeCampaign,
            recordsInProgress: records,
          });
        } else {
          commit("setActiveCampaign", {
            recordsAgreed: state.activeCampaign?.records
              ? [...state.activeCampaign?.records, ...records]
              : [...records],
          });
        }
        if (res.data.next) {
          fetchRecordsPage(res.data.next);
        }
        return records;
      }
      return await fetchRecordsPage(1);
    },
    //#endregion
  },
  getters: {
    isActiveCoursesLoading: (state) => !!state.activeCoursesPromise,
    isArchivedCoursesLoading: (state) => !!state.archivedCoursesPromise,
    isCourseLoading: (state) => !!state.coursePromise,
    isCampaignsLoading: (state) => !!state.campaignsPromise,
  },
};
