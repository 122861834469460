var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Board',{scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('BaseTitle',{staticClass:"d-block mb-4",domProps:{"textContent":_vm._s(_vm.$tc('campaign.record', 1))}}),_c('BaseParagraph',{staticClass:"d-block mb-1",domProps:{"textContent":_vm._s(_vm.$t('campaign.records.description'))}}),_c('BaseParagraph',{domProps:{"textContent":_vm._s(
          _vm.$t('campaign.records.agreedRecords', {
            count:
              _vm.campaign.recordsAgreed && _vm.campaign.recordsAgreed.length
                ? _vm.campaign.recordsAgreed.length
                : 0,
          })
        )}}),_c('CampaignDetailRecordsList',{attrs:{"list-headers":_vm.recordsHeaders,"records":_vm.campaign.recordsAgreed,"record-status":'AGREED',"is-loading-records":_vm.isLoadingRecordsAgreed}}),_c('BaseExpensionPanels',{staticClass:"my-4",attrs:{"multiple":""}},[_c('BaseExpensionPanel',{staticClass:"disagreed-panel mb-4",attrs:{"expand":"","disabled":_vm.isLoadingRecordsDisagreed,"eager":"","background-color":"secondary"}},[_c('template',{slot:"header"},[(_vm.isLoadingRecordsDisagreed)?_c('v-skeleton-loader',{staticClass:"skeleton",attrs:{"type":"text"}}):_c('BaseParagraph',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s(
                _vm.$t('campaign.records.disagreedRecords', {
                  count:
                    _vm.campaign.recordsDisagreed &&
                    _vm.campaign.recordsDisagreed.length
                      ? _vm.campaign.recordsDisagreed.length
                      : 0,
                })
              )}})],1),_c('template',{slot:"content"},[_c('CampaignDetailRecordsList',{attrs:{"list-headers":_vm.recordsHeaders,"records":_vm.campaign.recordsDisagreed,"record-status":'DISAGREED',"is-loading-records":_vm.isLoadingRecordsDisagreed}})],1)],2),_c('BaseExpensionPanel',{staticClass:"in-progress-panel",attrs:{"disabled":_vm.isLoadingRecordsInProgress,"expand":"","eager":""}},[_c('template',{slot:"header"},[(_vm.isLoadingRecordsInProgress)?_c('v-skeleton-loader',{staticClass:"skeleton",attrs:{"type":"text"}}):_c('BaseParagraph',{attrs:{"color":"accent"},domProps:{"textContent":_vm._s(
                _vm.$t('campaign.records.inProgressRecords', {
                  count:
                    _vm.campaign.recordsInProgress &&
                    _vm.campaign.recordsInProgress.length
                      ? _vm.campaign.recordsInProgress.length
                      : 0,
                })
              )}})],1),_c('template',{slot:"content"},[_c('CampaignDetailRecordsList',{attrs:{"list-headers":_vm.recordsHeaders,"records":_vm.campaign.recordsInProgress,"record-status":'IN_PROGRESS',"is-loading-records":_vm.isLoadingRecordsInProgress}})],1)],2)],1)]},proxy:true}])}),_c('RelaunchInvitationRecordDialog',{attrs:{"reminders":_vm.campaign.recordsInProgress}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }