<template>
  <div>
    <BaseTitle class="mb-6" v-text="$tc('campaign.campaign', 2)" />

    <CampaignDetailHeader
      v-if="activeCampaign"
      :campaign="activeCampaign"
      :course="activeCampaign.course"
      :is-fetching-active-campaign="isFetchingActiveCampaign"
      @open-create-campaign="dialogState = true"
      @open-campaign-invite-dialog="isCampaignInviteDialogOpen = true"
    />

    <CampaignDetailRecords
      v-if="activeCampaign && !/^DRAFT$/.test(activeCampaign.status)"
      :campaign="activeCampaign"
      :is-fetching-active-campaign="isFetchingActiveCampaign"
    />

    <campaign-create-and-edit-dialog
      v-if="dialogState"
      :dialog-state="dialogState"
      @close="dialogState = false"
    />
    <CampaignDeleteDialog />
    <CampaignInviteDialog
      v-if="isCampaignInviteDialogOpen"
      :dialog-state="isCampaignInviteDialogOpen"
      @close="isCampaignInviteDialogOpen = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CampaignDetailRecords from "@/modules/Campaign/Components/CampaignDetailRecords.vue";
import CampaignDetailHeader from "@/modules/Campaign/Components/CampaignDetailHeader.vue";
import CampaignInviteDialog from "@/modules/Campaign/Components/CampaignInviteDialog.vue";
import CampaignDeleteDialog from "@/modules/Campaign/Components/CampaignDeleteDialog.vue";
import CampaignCreateAndEditDialog from "../Components/CampaignCreateAndEditDialog.vue";

export default {
  name: "CampaignDetail",
  components: {
    CampaignDetailHeader,
    CampaignDetailRecords,
    CampaignDeleteDialog,
    CampaignInviteDialog,
    CampaignCreateAndEditDialog,
  },
  data() {
    return {
      isFetchingActiveCourse: false,
      isFetchingActiveCampaign: false,
      dialogState: false,
      isCampaignInviteDialogOpen: false,
    };
  },
  computed: {
    ...mapState({
      activeCampaign: (state) => state.course.activeCampaign,
    }),
  },
  watch: {
    activeCampaign: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.commit("course/setActiveCampaign", null);
    },
    async initialize() {
      this.isFetchingActiveCampaign = true;
      await this.$store.dispatch("course/fetchActiveCampaign", {
        organizationId: this.$route.params.organizationId,
        campaignId: this.$route.params.campaignId,
      });
      this.isFetchingActiveCampaign = false;
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[1].label = `${this.activeCampaign.name} `;
    },
  },
};
</script>
