<template>
  <div class="fl_container">
    <div class="tab_container" :class="{ tab_container_client: !needContact }">
      <div class="tab_header">
        <!-- FOLDER -->
        <div
          class="tab_header_item tab_header_item_special"
          @click="menu.folder = true"
        >
          <div class="all__select" @click.stop="">
            <input
              v-if="canArchive && folderDetail.folders.length"
              v-model="isAllSeclected"
              :checked="isAllChecked()"
              type="checkbox"
              class="check_folder"
              @input.stop="selectOrDeselectAllFolders"
            />
          </div>
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.folder" />
          </div>
          <div class="header_label">Dossier / Consentement</div>
          <div v-if="menu.folder" class="filter_menu">
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'name' }"
                @click.prevent.stop="changeOrderFilter('name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-name' }"
                @click.prevent.stop="changeOrderFilter('-name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
              <div class="filter_search_section">
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">Filtrer</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="filters.name"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                    @keyup.enter="resetMenu()"
                    @input="handleInputValue()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- CONTACT -->
        <div v-if="needContact" class="tab_header_item" @click="openContact()">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.contact" />
          </div>
          <div class="header_label">Contact</div>
          <div v-if="menu.contact" class="filter_menu" @click.stop>
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'client__email' }"
                @click.prevent.stop="changeOrderFilter('client__email')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-client__email' }"
                @click.prevent.stop="changeOrderFilter('-client__email')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
              <div class="filter_search_section">
                <v-progress-linear
                  v-if="contactData.loading"
                  class="progression"
                  indeterminate
                />
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">Filtrer</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="queryString"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                  />
                </div>
                <div class="search_items">
                  <v-checkbox
                    v-for="(contact, ci) in contactsToShow"
                    :key="`c-${ci}-${contact.email}`"
                    v-model="filters.client"
                    class="search_item"
                    hide-details
                    :value="contact.email.toLowerCase()"
                    @change="changeFilters()"
                  >
                    <template #label>
                      <div>
                        <span v-if="!!contact.firstname" class="firstname mr-1">
                          {{ `${contact.firstname}` }}
                        </span>
                        <span v-if="!!contact.lastname" class="firstname">
                          {{ `${contact.lastname}` }}
                        </span>
                        <div
                          class="check_label"
                          v-text="contact.email.toLowerCase()"
                        />
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- WORKFLOW -->
        <div class="tab_header_item" @click="menu.workflow = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.workflow" />
          </div>
          <div class="header_label">Workflow</div>
          <div v-if="menu.workflow" class="filter_menu">
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'workflow__name' }"
                @click.prevent.stop="changeOrderFilter('workflow__name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-workflow__name' }"
                @click.prevent.stop="changeOrderFilter('-workflow__name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
              <div class="filter_search_section">
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">Filtrer</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="queryString"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                  />
                </div>
                <div v-if="workflowsName.length" class="search_items">
                  <v-checkbox
                    v-for="(name, wi) in workflowsName.filter((d) =>
                      d.toLowerCase().includes(queryString.toLowerCase())
                    )"
                    :key="`wn-${wi}-${name}`"
                    v-model="filters.workflow"
                    class="search_item"
                    hide-details
                    :value="name"
                    @change="changeFilters()"
                  >
                    <template #label>
                      <div class="check_label" v-text="name" />
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- STATUS -->
        <div class="tab_header_item" @click="menu.status = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.status" />
          </div>
          <div class="header_label">Statut</div>
          <div v-if="menu.status" class="filter_menu">
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'status__name' }"
                @click.prevent.stop="changeOrderFilter('status__name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-status__name' }"
                @click.prevent.stop="changeOrderFilter('-status__name')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
              <div class="filter_search_section">
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">Filtrer</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="queryString"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                  />
                </div>

                <div v-if="statusName.length" class="search_items">
                  <v-checkbox
                    v-for="(name, si) in statusName.filter((d) =>
                      d.toLowerCase().includes(queryString.toLowerCase())
                    )"
                    :key="`sn-${si}-${name}`"
                    v-model="filters.status"
                    class="search_item"
                    hide-details
                    :value="name"
                    @change="changeFilters()"
                  >
                    <template #label>
                      <div class="check_label" v-text="name" />
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ASSING -->
        <div class="tab_header_item" @click="openCollaborator">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.assign" />
          </div>
          <div class="header_label">Collaborateur</div>
          <div v-if="menu.assign" class="filter_menu" @click.stop>
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'assign__email' }"
                @click.prevent.stop="changeOrderFilter('assign__email')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-assign__email' }"
                @click.prevent.stop="changeOrderFilter('-assign__email')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
              <div class="filter_search_section">
                <v-progress-linear
                  v-if="collaboratorData.loading"
                  class="progression"
                  indeterminate
                />
                <div class="search_header">
                  <div class="filter_icon">
                    <BaseIcon icon="$mdiFilterOutline" />
                  </div>
                  <div class="header_label">Filtrer</div>
                </div>
                <div class="search_input">
                  <input
                    v-model="queryString"
                    placeholder="Rechercher.."
                    class="input"
                    type="text"
                  />
                </div>

                <div v-if="collaboratorsEmail.length" class="search_items">
                  <v-checkbox
                    v-for="(email, si) in collaboratorsEmail.filter((d) =>
                      d.toLowerCase().includes(queryString.toLowerCase())
                    )"
                    :key="`cn-${si}-${collaboratorsEmail}`"
                    v-model="filters.assign"
                    class="search_item"
                    hide-details
                    :value="email"
                    @change="changeFilters()"
                  >
                    <template #label>
                      <div class="check_label" v-text="email" />
                    </template>
                  </v-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DATE_UPDATED -->
        <div class="tab_header_item" @click="menu.dateUpdated = true">
          <div class="header_icon">
            <BaseIcon :icon="headerIcons.dateUpdated" />
          </div>
          <div class="header_label">
            {{ !needContact ? "Etat du dossier" : "modification" }}
          </div>
          <div v-if="menu.dateUpdated" class="filter_menu">
            <div
              class="filter_menu_overlay"
              @click.prevent.stop="resetMenu()"
            />
            <div class="filter_menu_content">
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == 'date_updated' }"
                @click.prevent.stop="changeOrderFilter('date_updated')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowUp" />
                </div>
                <div class="item_label">Par ordre croissant</div>
              </div>
              <div
                class="menu_item"
                :class="{ active_menu: filters.ordering == '-date_updated' }"
                @click.prevent.stop="changeOrderFilter('-date_updated')"
              >
                <div class="item_icon">
                  <BaseIcon icon="$mdiArrowDown" />
                </div>
                <div class="item_label">Par ordre décroissant</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="progress_linear">
          <v-progress-linear indeterminate />
        </div>
      </div>
      <router-link
        v-for="(folder, index) in folderDetail.folders"
        :key="index"
        class="tab_row"
        :to="{
          name: 'folderDetail',
          params: {
            organizationId: organizationId,
            folderId: folder.id,
          },
        }"
      >
        <div class="tab_col">
          <div class="name__container" @click.stop="">
            <input
              v-if="canArchive"
              :key="`folder-${folder.id}`"
              type="checkbox"
              class="check_folder"
              :checked="isChecked(folder.id)"
              @input="selectFolder(folder.id)"
            />
            <div>
              <div class="folder_name">
                <BaseBadge
                  :color="getValidationColor(folder)"
                  class="badge"
                  inline
                  dot
                />
                <div class="col_label" v-text="folder.name" />
              </div>
              <BaseChip
                small
                :type="getStatusType(folder)"
                class="records__max"
              >
                {{ folder.recordsAgreeCount + "/" + folder.recordsCount }}
              </BaseChip>
            </div>
          </div>
        </div>
        <div v-if="needContact" class="tab_col">
          <template v-if="folder.client && !folder.client.isTemporary">
            <div class="col_label" v-text="folder.client.fullName" />
            <div
              class="col_label label_secondary"
              v-text="folder.client.email"
            />
          </template>
          <div v-else class="col_label" v-text="'-'" />
        </div>
        <div class="tab_col">
          <div class="col_label" v-text="findWorkflowName(folder)" />
        </div>
        <div class="tab_col">
          <div
            class="col_label label_secondary"
            v-text="getStatusName(folder)"
          />
        </div>
        <div class="tab_col">
          <div class="col_label" v-text="getAssignName(folder)" />
        </div>
        <div class="tab_col">
          <div
            v-if="needContact"
            class="col_label"
            v-text="$d(new Date(folder.dateUpdated), 'detailed')"
          />
          <div v-else>
            <div
              class="chips"
              :class="{ active_chips: folder.state === 'OPEN' }"
            >
              {{ folder.state === "OPEN" ? "Actif" : "Archivé" }}
            </div>
          </div>
        </div>
      </router-link>
      <div v-if="!loading && !folderDetail.folders.length" class="tab_row">
        <div class="no_data">Pas de dossier</div>
      </div>
    </div>

    <div
      v-if="
        folderDetail.folders.length > 5 ||
        folderDetail.next > 0 ||
        folderDetail.previous > 0
      "
      class="pagination"
    >
      <div class="item_per_page">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <select
              v-model="filters.pageSize"
              class="select__page"
              v-on="on"
              @change="changeFilters"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </template>
          <span v-text="$t('utils.numberOfFolderBypage')" />
        </v-tooltip>
      </div>
      <v-pagination
        v-if="folderDetail.next > 0 || folderDetail.previous > 0"
        v-model="filters.page"
        :total-visible="filters.pageSize"
        :length="activeFolderPages"
        @input="changeFilters(false)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientAPI from "@/modules/Client/Services/client.api";
import OrganizationAPI from "@/core/Services/organization.api";

export default {
  name: "FolderListTable",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    folderDetail: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    loading: {
      type: Boolean,
    },
    needContact: {
      type: Boolean,
    },
    canArchive: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAllSeclected: false,
      filters: {
        state: "OPEN",
        pageSize: 10,
        page: 1,
        ordering: "",
        q: "",
        name: "",
        status: [],
        assign: [],
        workflow: [],
        client: [],
      },
      queryString: "",
      menu: {
        folder: false,
        contact: false,
        workflow: false,
        status: false,
        assign: false,
        dateUpdated: false,
      },
      contactData: {
        page: 1,
        pageSize: 10,
        loading: false,
        contacts: [],
      },
      collaboratorData: {
        page: 1,
        loading: false,
        collaborators: [],
      },
    };
  },

  computed: {
    activeFolderPages() {
      if (!this.folderDetail.count) return 0;
      return Math.ceil(this.folderDetail.count / this.filters.pageSize);
    },
    headerIcons() {
      const icons = {};
      icons.folder =
        this.filters.ordering == "name" ? "$mdiMenuUp" : "$mdiMenuDown";
      icons.contact =
        this.filters.ordering == "client__email"
          ? "$mdiMenuUp"
          : "$mdiMenuDown";
      icons.workflow =
        this.filters.ordering == "workflow__name "
          ? "$mdiMenuUp"
          : "$mdiMenuDown";
      icons.status =
        this.filters.ordering == "status__name" ? "$mdiMenuUp" : "$mdiMenuDown";
      icons.assign =
        this.filters.ordering == "assign__email"
          ? "$mdiMenuUp"
          : "$mdiMenuDown";
      icons.dateUpdated =
        this.filters.ordering == "date_updated" ? "$mdiMenuUp" : "$mdiMenuDown";
      return icons;
    },
    ...mapState({
      workflows: (state) => state.workflow.workflows,
      selectedFolderToArchive: (state) => state.folder.selectedFolderToArchive,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    workflowsName() {
      if (!this.workflows?.length) return [];
      return this.workflows.map((w) => w.name);
    },
    statusName() {
      if (!this.workflows?.length) return [];
      let tmp = [];
      this.workflows
        .filter((w) => {
          if (!this.filters.workflow.length) return w;
          return this.filters.workflow.includes(w.name);
        })
        .forEach((workflow) => {
          workflow.statuses.forEach((status) => tmp.push(status.name));
        });
      return tmp;
    },
    collaboratorsEmail() {
      if (!this.collaboratorData.collaborators?.length) return [];
      return this.collaboratorData.collaborators.map((c) => c.user.email);
    },
    contactsToShow() {
      if (!this.contactData.contacts?.length) return [];
      return this.contactData.contacts.filter(
        (contact) =>
          contact.email
            .toLowerCase()
            .includes(this.queryString.toLowerCase()) ||
          contact.firstname
            ?.toLowerCase()
            ?.includes(this.queryString.toLowerCase()) ||
          contact.lastname
            ?.toLowerCase()
            ?.includes(this.queryString.toLowerCase())
      );
    },
  },

  watch: {
    modelValue: {
      handler(value) {
        this.filters = value;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    isChecked(folderId) {
      return this.selectedFolderToArchive.some((item) => item == folderId);
    },
    isAllChecked() {
      if (!this.folderDetail.folders.length)
        return (this.isAllSeclected = false);
      const value =
        this.selectedFolderToArchive.length == this.folderDetail.folders.length;
      if (value) this.isAllSeclected = true;
      else this.isAllSeclected = false;
    },
    selectFolder(folderId) {
      const indexOfFolderId = this.selectedFolderToArchive.indexOf(folderId);
      const tempFolder = [...this.selectedFolderToArchive];
      if (indexOfFolderId != -1) tempFolder.splice(indexOfFolderId, 1);
      else tempFolder.push(folderId);
      this.$store.commit("folder/setSelectedFolderToArchive", tempFolder);
    },
    selectOrDeselectAllFolders() {
      if (!this.isAllSeclected) {
        return this.selectAllFolders();
      }
      this.$store.commit("folder/setSelectedFolderToArchive", []);
    },
    selectAllFolders() {
      const selectedFolders = this.folderDetail.folders.map(
        (folder) => folder.id
      );
      this.$store.commit("folder/setSelectedFolderToArchive", selectedFolders);
    },
    resetMenu() {
      this.menu = {
        folder: false,
        contact: false,
        workflow: false,
        status: false,
        assign: false,
        dateUpdated: false,
      };
      this.queryString = "";
    },
    changeOrderFilter(value) {
      this.filters.ordering = value;
      this.resetMenu();
      this.changeFilters();
    },
    handleInputValue(resetPage = true) {
      if (resetPage) this.filters.page = 1;
      this.$emit("inputValue", this.filters);
    },
    changeFilters(resetPage = true) {
      if (resetPage) this.filters.page = 1;
      this.$emit("changeValue", this.filters);
    },
    removeToFilter(key, value) {
      this.filters[key].splice(this.filters.client.indexOf(value), 1);
      this.changeFilters();
    },
    getStatusType(item) {
      if (this.folderStateFilter == "CLOSED") return "done";
      if (item.recordsAgreeCount == 0) {
        return "primary";
      } else if (item.recordsAgreeCount < item.recordsCount) {
        return "accent";
      }
      return "tertiary";
    },
    getAssignName(folder) {
      if (!folder.assign) return "Non assigné";
      if (folder.assign.firstname || folder.assign.lastname)
        return `${folder.assign.firstname} ${folder.assign.lastname}`.trim();
      return `${folder.assign.email}`;
    },
    getStatusName(folder) {
      if (!this.workflows || isNaN(folder.workflow)) return "-";
      return `${
        this.workflows
          ?.find((w) => w.id == folder.workflow)
          ?.statuses?.findIndex((s) => s.id == folder.status) + 1
      }.
      ${
        this.workflows
          ?.find((w) => w.id == folder.workflow)
          ?.statuses?.find((s) => s.id == folder.status)?.name
      }`;
    },
    findWorkflowName(folder) {
      if (!this.workflows || isNaN(folder.workflow)) return "-";
      return this.workflows.find((w) => w.id == folder.workflow)?.name;
    },
    getValidationColor(item) {
      switch (item.validationStatus) {
        case "UPDATE_REQUESTED":
          return "#ff5267";
        case "UPDATED":
          return "#ffab5a";
        case "VALIDATED":
          return "#18a300";
        default:
          return item.hasSpamEmail ? "#FF6B00" : "#b6bdff";
      }
    },
    openContact() {
      this.menu.contact = true;
      this.fetchClients();
    },
    openCollaborator() {
      this.menu.assign = true;
      this.fetchCollaborators();
    },
    async fetchClients() {
      if (this.contactData.loading || this.contactData.contacts.length) return;
      this.contactData.loading = true;
      while (this.contactData.page && this.organizationId) {
        const res = await ClientAPI.getOrganizationClients(
          this.organizationId,
          this.contactData.page,
          this.contactData.pageSize
        );
        this.contactData.contacts = [
          ...this.contactData.contacts,
          ...res.data.results,
        ];
        this.contactData.page = res.data.next;
      }
      this.contactData.loading = false;
    },
    async fetchCollaborators() {
      if (
        this.collaboratorData.loading ||
        this.collaboratorData.collaborators.length
      )
        return;
      this.collaboratorData.loading = true;
      while (this.collaboratorData.page && this.organizationId) {
        const res = await OrganizationAPI.getOrganizationCollaborators(
          this.organizationId,
          this.collaboratorData.page
        );
        this.collaboratorData.collaborators = [
          ...this.collaboratorData.collaborators,
          ...res.data.results,
        ];
        this.collaboratorData.page = res.data.next;
      }
      this.collaboratorData.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_container_client {
  .tab_header_item {
    width: calc(100% / 5) !important;
  }
  .tab_col {
    width: calc(100% / 5) !important;
  }
}

.tab_container {
  background: #fcfcfc;
  box-shadow: 0px 5px 15px 0px rgba(20, 20, 243, 0.1),
    0px 2px 2px 0px rgba(0, 15, 144, 0.2);
  .tab_header {
    display: flex;
    position: relative;
    .tab_header_item {
      flex: none;
      background: #fcfcfc;
      width: calc(100% / 6);
      padding: 16px 32px;
      background: #fff;
      transition: all 0.25s;
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #d0dffd;
      }
      .header_icon {
        background: #d0dffd;
        flex: none;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
          }
        }
      }
      .header_label {
        color: #353542;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .filter_menu {
        position: absolute;
        z-index: 10;
        top: 80%;
        right: 0;
        z-index: 10000;

        .filter_menu_overlay {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          z-index: 1;
          background: transparent;
        }
        .filter_menu_content {
          position: relative;
          z-index: 2;
          width: 250px;
          padding: 8px 0;
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 5px 15px 0px rgba(20, 20, 243, 0.1);
          .menu_item {
            display: flex;
            align-items: center;
            padding: 10px 16px;
            border-bottom: 1px solid #cfcfdc;
            transition: all 0.25s;
            &:last-child {
              border-bottom: none;
            }
            .item_icon {
              margin-right: 4px;
              height: 16px;
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              &::v-deep {
                .v-icon {
                  height: 16px;
                  width: 16px;
                  color: #353542;
                }
              }
            }
            .item_label {
              color: #353542;
              font-family: "Inter";
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
            &.active_menu {
              .item_icon {
                &::v-deep {
                  .v-icon {
                    color: #2536cc;
                  }
                }
              }
              .item_label {
                color: #2536cc;
              }
            }
            &:hover {
              .item_icon {
                &::v-deep {
                  .v-icon {
                    color: #2536cc;
                  }
                }
              }
              .item_label {
                color: #2536cc;
              }
            }
          }
          .filter_search_section {
            padding: 10px 16px;
            position: relative;
            .progression {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
            }
            .search_header {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .filter_icon {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
                &::v-deep {
                  .v-icon {
                    color: #484964;
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .header_label {
                color: #484964;
                font-family: "Inter";
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }
            }
            .search_input {
              width: 100%;
              .input {
                color: #353542;
                font-family: "Inter";
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                padding: 6px 16px;
                border: 1px solid #000;
                border-radius: 5px;
                width: 100%;
                &:focus {
                  border: 1px solid #2536cc;
                  outline: 3px solid #ebf2ff;
                }
              }
              &::v-deep {
                .v-input__append-inner {
                  display: none;
                }

                .v-input {
                  color: #353542;
                  font-family: "Inter";
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 500;
                  &.v-input--is-focused {
                    outline: 3px solid #ebf2ff;
                  }
                }
              }
              .selected_item {
                border-radius: 36px;
                background: #fff;
                display: flex;
                align-items: center;
                max-width: 100%;
                .item_label {
                  color: #353542;
                  font-family: "Inter";
                  font-size: 12px;
                  line-height: 20px;
                  font-weight: 500;
                  width: calc(100% - 22px);
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .close_icon {
                  width: 18px;
                  height: 18px;
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  &::v-deep {
                    .v-icon {
                      height: 18px;
                      width: 18px;
                      color: rgba(0, 0, 0, 0.6);
                    }
                  }
                }
              }
            }
            .search_items {
              width: 100%;
              max-height: 176px;
              overflow: auto;
              &::-webkit-scrollbar {
                display: none;
              }

              .search_item {
                width: 100%;
                margin-bottom: 16px;
                &:last-child {
                  margin-bottom: 0;
                }
                .check_label {
                  width: 186px;
                  text-wrap: wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  color: #353542;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
    .tab_header_item_special {
      padding: 16px 16px;
    }
    .progress_linear {
      position: absolute;
      width: 100%;
      bottom: -4px;
      left: 0;
      z-index: 2;
    }
  }
  .tab_row {
    display: flex;
    background: #fcfcfc;
    border-top: 1px solid #c4c4c4;
    transition: all 0.25s;
    cursor: pointer;
    text-decoration: none;
    .tab_col {
      flex: none;
      padding: 16px;
      width: calc(100% / 6);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      .col_label {
        color: #353542;
        font-family: "Inter";
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: wrap;
        &.label_secondary {
          color: #707080;
        }
      }
      .chips {
        margin-top: 2px;
      }
      .name__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .folder_name {
          display: flex;
          align-items: center;
          .bagde {
            margin-right: 4px;
          }
          .records__max {
            width: 30px;
          }
        }
      }
    }

    .no_data {
      display: flex;
      justify-content: center;
      padding: 20px 16px;
      color: #353542;
      font-family: "Inter";
      font-size: 12px;
      line-height: 16px;
      font-style: normal;
      font-weight: 400;
      width: 100%;
    }
    &:hover {
      background: #e5e5e5;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 612px;
  margin: auto;
  margin-top: 20px;
  .item_per_page {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .select__page {
      width: 34px;
      height: 34px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #707080;
      border-radius: 4px;
      outline: none;
      color: #353542;
      font-family: "Inter";
      font-size: 16px;
      line-height: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.chips {
  border-radius: 5px;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #ff6b00;
  background-color: #fff3f0;
}
.active_chips {
  color: #18a300;
  background-color: #f0ffd6;
}
.check_folder {
  min-height: 16px;
  min-width: 16px;
}
.all__select {
  margin-right: 16px;
}
.firstname {
  font-size: 12px;
}
</style>
