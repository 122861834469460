import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  getOrganizationClients(organizationId, page, pageSize, search = "") {
    const params = {
      page,
      page_size: pageSize,
    };
    if (search) params.search = search;
    return cosmoAPI.get(`/organization/${organizationId}/client/`, {
      params,
    });
  },
  getOrganizationClient(organizationId, clientId) {
    return cosmoAPI.get(`/organization/${organizationId}/client/${clientId}/`);
  },
  getOrganizationClientRecords(organizationId, clientId, page) {
    return cosmoAPI.get(
      `/organization/${organizationId}/client/${clientId}/record/`,
      {
        params: {
          page,
        },
      }
    );
  },
  getOrganizationClientLogs(organizationId, clientId, page) {
    return cosmoAPI.get(
      `/organization/${organizationId}/client/${clientId}/log/`,
      {
        params: {
          page,
        },
      }
    );
  },
  postOrganizationCreateClient(organizationId, payload) {
    return cosmoAPI.post(`/organization/${organizationId}/client/`, payload);
  },
  postRelaunchInvitationRecordFromClient({
    organizationId,
    clientId,
    recordId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/client/${clientId}/record/${recordId}/remind/`,
      payload
    );
  },
  searchOrganizationClients(organizationId, page, pageSize, search) {
    return cosmoAPI.get(`/organization/${organizationId}/client/`, {
      params: {
        page,
        page_size: pageSize,
        search,
      },
    });
  },
};
