import ClientAPI from "../Services/client.api";

import Client from "@/core/Models/Client";
import Record from "@/core/Models/Record";
import Question from "@/core/Models/Question";
import Log from "@/core/Models/Log";

export default {
  namespaced: true,
  state: {
    activeClient: null,
    isFetchingActiveClient: false,
    records: [],
    isFetchingRecords: false,
    activeQuestion: null,
    clients: [],
    clientsCount: 0,
    isFetchingClients: null,
    logs: [],
    modals: {
      isDisplayLogsDialogOpen: false,
      isDisplayAnswerDialogOpen: false,
      isClientCreateDialogOpen: false,
    },
  },
  mutations: {
    //#region client List
    setClients(state, payload) {
      state.clients = payload.map((client) => new Client(client));
    },
    setClientsCount(state, payload) {
      state.clientsCount = Number.parseInt(payload);
    },
    setIsFetchingClients(state, payload) {
      state.isFetchingClients = payload;
    },
    //#endregion
    //#region Client detail
    setActiveClient(state, payload) {
      state.activeClient = payload ? new Client(payload) : null;
    },
    setIsFetchingActiveClient(state, payload) {
      state.isFetchingActiveClient = payload;
    },
    //#endregion
    //#region Record
    setRecords(state, payload) {
      state.records = payload.map((record) => {
        return new Record(record);
      });
    },
    setIsFetchingRecords(state, payload) {
      state.isFetchingRecords = payload;
    },
    //#endregion
    //#region Question and Datatype
    setActiveQuestion(state, payload) {
      state.activeQuestion = payload ? new Question(payload) : null;
    },
    //#endregion
    //#region Logs
    setLogs(state, payload) {
      state.logs = payload ? payload.map((log) => new Log(log)) : [];
    },
    //#endregion
    //#region Modals
    setDisplayLogsDialog(state, payload) {
      state.modals.isDisplayLogsDialogOpen = payload;
    },
    setDisplayAnswerDialog(state, payload) {
      state.modals.isDisplayAnswerDialogOpen = payload;
    },
    setDisplayClientDialog(state, payload) {
      state.modals.isClientCreateDialogOpen = payload;
    },
    //#endregion
  },
  actions: {
    async fetchOrganizationClients(
      { commit, state },
      { organizationId, page, pageSize }
    ) {
      commit("setIsFetchingClients", true);
      const res = await ClientAPI.getOrganizationClients(
        organizationId,
        page,
        pageSize
      );
      let clientsPage = res && res.data.results;
      if (page) {
        commit("setClients", clientsPage);
        commit("setClientsCount", res.data.count);
      } else {
        commit("setClients", [...state.clients, ...clientsPage]);
      }

      commit("setIsFetchingClients", false);
      return clientsPage;
    },
    //#region Client
    async fetchActiveClient({ commit }, { orgnizationId, clientId }) {
      commit("setIsFetchingActiveClient", true);
      const res = await ClientAPI.getOrganizationClient(
        orgnizationId,
        clientId
      );

      const client = res.data;
      commit("setActiveClient", client);
      commit("setIsFetchingActiveClient", false);
      return client;
    },
    //#endregion
    //#region Records
    async fetchOrganizationClientRecords(
      { commit, state },
      { organizationId, clientId }
    ) {
      async function fetchOrganizationClientRecordsPage(page) {
        const res = await ClientAPI.getOrganizationClientRecords(
          organizationId,
          clientId,
          page
        );
        const records = res.data.results.map((r) => ({
          ...r,
          email: state.activeClient?.email,
        }));
        if (page === 1) {
          commit("setRecords", records);
        } else {
          commit("setRecords", [...state.records, ...records]);
        }
        if (res.data.next) {
          fetchOrganizationClientRecordsPage(res.data.next);
        }
      }
      commit("setIsFetchingRecords", true);
      await fetchOrganizationClientRecordsPage(1);
      commit("setIsFetchingRecords", false);
    },
    //#endregion
    //#region Logs
    async fetchOrganizationClientLogs(
      { commit, state, rootState },
      { organizationId, clientId }
    ) {
      async function fetchOrganizationClientLogsPage(page) {
        const result = await ClientAPI.getOrganizationClientLogs(
          organizationId,
          clientId,
          page
        );
        if (
          rootState.organization?.activeOrganization?.id !==
          Number.parseInt(organizationId)
        ) {
          return;
        }
        const logsPage = result.data.results;
        commit("setLogs", [...state.logs, ...logsPage]);
        if (result.data.next) {
          fetchOrganizationClientLogsPage(result.data.next);
        }
        return logsPage;
      }
      commit("setLogs", []);
      return await fetchOrganizationClientLogsPage(1);
    },
    //#endregion
    async searchClients(
      { commit, state },
      { organizationId, page, pageSize, search }
    ) {
      commit("setIsFetchingClients", true);
      const res = await ClientAPI.searchOrganizationClients(
        organizationId,
        page,
        pageSize,
        search
      );
      let clientsPage = res && res.data.results;
      if (page) {
        commit("setClients", clientsPage);
        commit("setClientsCount", res.data.count);
      } else {
        commit("setClients", [...state.clients, ...clientsPage]);
      }

      commit("setIsFetchingClients", false);
      return clientsPage;
    },
  },
};
