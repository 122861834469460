var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Board',[_c('template',{slot:"header"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseTitle',{staticClass:"d-block",domProps:{"textContent":_vm._s(_vm.$tc('organization.course', 2))}})],1)]),_c('template',{slot:"main"},[_c('BaseDataTable',{staticClass:"elevation-1 mb-6",attrs:{"headers":_vm.COURSES_LIST_HEADER,"items":_vm.courses,"loading":_vm.isFetchingOrganizationCourses,"loading-text":_vm.$t('utils.loadingData')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"row--link"},[_c('td',[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":{
                  name: 'courseDetail',
                  params: {
                    organizationId: _vm.activeOrganization.id,
                    courseId: item.id,
                  },
                }}},[_c('BaseParagraph',{staticClass:"d-block",domProps:{"textContent":_vm._s(item.name)}})],1)],1),_c('td',[_c('router-link',{staticClass:"text-center px-0",attrs:{"to":{
                  name: 'courseDetail',
                  params: {
                    organizationId: _vm.activeOrganization.id,
                    courseId: item.id,
                  },
                }}},[(item.anonymous)?_c('BaseIcon',{attrs:{"icon":"$mdiCheck","color":"primary"}}):_c('BaseIcon',{attrs:{"icon":"$mdiClose","color":"secondary"}})],1)],1),_c('td',[_c('router-link',{staticClass:"text-center px-0",attrs:{"to":{
                  name: 'courseDetail',
                  params: {
                    organizationId: _vm.activeOrganization.id,
                    courseId: item.id,
                  },
                }}},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.campaignsCount)}})],1)],1),_c('td',[_c('router-link',{staticClass:"text-center px-0",attrs:{"to":{
                  name: 'courseDetail',
                  params: {
                    organizationId: _vm.activeOrganization.id,
                    courseId: item.id,
                  },
                }}},[_c('BaseParagraph',{domProps:{"textContent":_vm._s(item.activeRecordsCount)}})],1)],1)])]}}])})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }