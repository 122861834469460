<template>
  <div>
    <BaseTitle class="d-block" v-text="$tc('campaign.campaign', 2)" />
    <Board class="my-6">
      <template slot="header">
        <div class="header-container mt-5">
          <div class="header">
            <BaseButton icon="$mdiPlus" @click="dialogState = true">
              {{ $t("campaign.create") }}
            </BaseButton>
            <div v-if="activeFilters.length" class="header_center">
              <div class="filters">
                <div class="filter_icon">
                  <BaseIcon icon="$mdiFilterOutline" />
                </div>
                <div
                  v-for="(filter, i) in activeFilters"
                  :key="`f-${i}`"
                  class="filter_item"
                >
                  <div v-if="filter.prependIcon" class="filter_item_icon">
                    <BaseIcon :icon="filter.prependIcon" />
                  </div>
                  <div class="filter_item_label">
                    {{ filter.label }}
                  </div>
                  <div class="close_icon" @click="removeFilter(filter)">
                    <BaseIcon icon="$mdiClose" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="main">
        <campaign-list-table
          v-model="activeCampaign.filters"
          :campaign-detail="activeCampaign.detail"
          :loading="activeCampaign.loading"
          :organization-id="activeOrganization.id"
          @inputValue="handleInputFilter"
          @update-filter-value="updateFilterValue"
          @changeValue="fetchActiveCampaign"
        />
      </template>
    </Board>
    <campaign-create-and-edit-dialog
      v-if="dialogState"
      :dialog-state="dialogState"
      @close="dialogState = false"
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board.vue";
import { getOrganizationCampaigns } from "@/modules/Editor/Services/campaign.service";

import { mapState } from "vuex";
import CampaignCreateAndEditDialog from "./CampaignCreateAndEditDialog.vue";
import CampaignListTable from "./CampaignListTable.vue";
import Campaign from "@/core/Models/Campaign";

export default {
  name: "CampaignList",
  components: {
    Board,
    CampaignCreateAndEditDialog,
    CampaignListTable,
  },
  data() {
    return {
      dialogState: false,
      courses: [],
      isFetchingCampaigns: false,
      activeCampaign: {
        loading: false,
        filters: {
          pageSize: 10,
          page: 1,
          ordering: "",
          name: "",
          status: "",
          course__name: "",
          course__anonymous: null,
          auto_reminder: null,
        },
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          campaigns: [],
        },
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    // new
    activeFilters() {
      const filters = [];
      let tmpFilters = this.activeCampaign.filters;
      if (tmpFilters.ordering) {
        const orderFilter = {
          prependIcon: tmpFilters.ordering.startsWith("-")
            ? "$mdiArrowDown"
            : "$mdiArrowUp",
          label: "",
          key: "ordering",
        };
        switch (tmpFilters.ordering) {
          case "name":
          case "-name":
            orderFilter.label = this.$tc("campaign.campaign", 2);
            break;
          case "course__anonymous":
          case "-course__anonymous":
            orderFilter.label = this.$t("editor.anonymous");
            break;
          case "course":
          case "-course":
            orderFilter.label = this.$t("utils.course");
            break;
          case "status":
          case "-status":
            orderFilter.label = this.$t("utils.status");
            break;
          case "auto_reminder":
          case "-auto_reminder":
            orderFilter.label = this.$t("course.autoReminder");
            break;
        }
        filters.push(orderFilter);
      }
      if (tmpFilters.name) {
        filters.push({
          label: `${this.$tc("campaign.campaign", 2)} : ${tmpFilters.name}`,
          key: "name",
        });
      }
      if (tmpFilters.course__name) {
        filters.push({
          label: `${this.$t("utils.course")} : ${tmpFilters.course__name}`,
          key: "course__name",
        });
      }
      if (tmpFilters.status) {
        filters.push({
          label: `${this.$tc("utils.status", 2)} : ${this.getCampaignStatus(
            tmpFilters.status
          )}`,
          key: "status",
        });
      }
      if (typeof tmpFilters.auto_reminder == "boolean") {
        const text = tmpFilters.auto_reminder
          ? this.$tc("utils.active", 1)
          : this.$tc("utils.active", 2);
        filters.push({
          label: `${this.$t("course.autoReminder")} : ${text}`,
          key: "auto_reminder",
        });
      }
      if (typeof tmpFilters.course__anonymous == "boolean") {
        const text = tmpFilters.course__anonymous
          ? this.$tc("utils.isAnonymous", 2)
          : this.$tc("utils.isAnonymous", 1);
        filters.push({
          label: `${this.$t("editor.anonymous")} : ${text}`,
          key: "course__anonymous",
        });
      }
      return filters;
    },
  },
  watch: {
    "activeOrganization.id": {
      handler() {
        this.initialize();
      },
      immediate: true,
    },
  },
  methods: {
    getCampaignStatus(status) {
      switch (status) {
        case "DRAFT":
          return this.$t("campaign.status.draft");
        case "IN_PROGRESS":
          return this.$t("campaign.status.launched");
        case "CLOSED":
          return this.$t("campaign.status.closed");
        default:
          return "-";
      }
    },
    initialize() {
      this.fetchActiveCampaign();
    },
    // new

    updateFilterValue({ filterKey, value }) {
      this.activeCampaign.filters[filterKey] = value;
    },
    async fetchActiveCampaign() {
      this.activeCampaign.loading = true;
      const res = await getOrganizationCampaigns({
        organizationId: this.activeOrganization.id,
        filters: this.activeCampaign.filters,
      });
      this.activeCampaign.detail = {
        ...res.data,
        campaigns: res.data.results.map((f) => {
          const res = new Campaign(f);
          return res;
        }),
      };
      this.activeCampaign.loading = false;
    },

    removeFilter(filter) {
      if (filter.key == "q") this.queryString = "";
      this.activeCampaign.filters[filter.key] = Array.isArray(
        this.activeCampaign.filters[filter.key]
      )
        ? []
        : "";
      this.fetchActiveCampaign();
    },
    handleInputFilter() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.fetchActiveCampaign();
      }, 500);
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  .header_left {
    width: 167px;
    flex: none;
  }
  .header_right {
    width: 250px;
    flex: none;
  }
  .header_center {
    width: calc(100% - 417px);
    overflow: hidden;
  }
  .filters {
    display: flex;
    padding: 0 24px;
    align-items: center;
    .filter_icon {
      flex: none;
      background: #f4f7ff;
      border-radius: 9px;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      &::v-deep {
        .v-icon {
          height: 20px;
          width: 20px;
          color: #242533;
        }
      }
    }

    .filter_item {
      border-radius: 36px;
      background: #f4f7ff;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      margin-left: 8px;
      .filter_item_icon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #707080;
          }
        }
      }
      .filter_item_label {
        color: #242533;
        font-family: "Inter";
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
        font-weight: 500;
      }
      .close_icon {
        width: 18px;
        height: 18px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::v-deep {
          .v-icon {
            height: 18px;
            width: 18px;
            color: #2536cc;
          }
        }
      }
    }
  }
}
.tab_items {
  overflow: visible;
}
.header-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
</style>
