<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="my-6 d-flex align-center">
        <BaseAvatar :size="78" class="mr-6 flex-shrink-0" :src="logo" />
        <BaseTitle v-text="welcomeText" />
      </div>
      <BaseButtonIcon
        v-if="isManager"
        class="mr-1"
        icon="$mdiCog"
        :to="{
          name: 'admin',
        }"
        :icon-size="32"
        large
        @click.stop=""
      />
    </div>
    <OrganizationDashboardStatistics />
    <OrganizationDashboardCourses />
  </div>
</template>

<script>
import OrganizationDashboardCourses from "../Components/OrganizationDashboard/OrganizationDashboardCourses";
import OrganizationDashboardStatistics from "../Components/OrganizationDashboard/OrganizationDashboardStatistics";

import { mapState } from "vuex";

export default {
  name: "OrganizationDashboard",
  components: {
    OrganizationDashboardCourses,
    OrganizationDashboardStatistics,
  },
  data() {
    return {
      organization: null,
      isFetchingOrganization: false,
      collaborators: {
        loading: true,
        items: [],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    logo() {
      return this.activeOrganization?.logo;
    },
    welcomeText() {
      return `${this.$t("organization.welcomeTitle")} ${
        this.activeOrganization?.name
      }`;
    },
    isManager() {
      return this.user
        ? this.user.isManager(this.activeOrganization?.id)
        : false;
    },
  },
};
</script>
