<template>
  <div>
    <BaseDialog
      max-width="800"
      :persistent="isSubmitting"
      :value="isRelaunchInvitationDialogOpen"
      @click:outside="reset()"
      @keydown.esc="reset()"
    >
      <template #modal>
        <BaseCosmoCard>
          <template #title>
            <div class="modal_header">
              <BaseTitle v-text="$t('utils.relaunch')" />
              <BaseButtonIcon
                class="close_icon"
                icon="$mdiClose"
                @click="reset()"
              />
            </div>
          </template>
          <div v-if="recordToRelaunch" class="modal_content">
            <div class="item_section mb-4">
              <div class="label" v-text="$tc('utils.recipient', 0)" />
              <div class="desc" v-text="recordToRelaunch.email" />
            </div>
            <div class="item_section mb-4">
              <div class="label" v-text="'Parcours envoyé'" />
              <div class="desc" v-text="courseName" />
              <div class="item_footer" v-text="lastRemindDate" />
            </div>
            <div class="item_section mb-4">
              <div class="label" v-text="'Message de relance'" />
              <BaseMarkdownEditor v-model="message" :with-link="false" />
            </div>
          </div>
          <div class="modal_footer">
            <BaseButton
              :disabled="isSubmitting"
              color="primary"
              type="primary"
              @click="remindLogsDialogState = true"
            >
              {{ $t("utils.showLogs") }}
            </BaseButton>
            <BaseButton
              :disabled="isSubmitting"
              color="grey"
              type="secondary"
              @click="reset()"
            >
              {{ $t("utils.cancel") }}
            </BaseButton>
            <BaseButton
              :loading="isSubmitting"
              color="primary"
              type="primary"
              @click="relaunchInvitation()"
            >
              {{ $t("utils.relaunch") }}
            </BaseButton>
          </div>
        </BaseCosmoCard>
      </template>
    </BaseDialog>

    <RecordRelaunchLogsDialog
      :is-open="remindLogsDialogState"
      :all-records-logs="recordLogs"
      @close="remindLogsDialogState = false"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { postRelaunchInvitationRecordFromClient } from "../../Client/Services/client.service";
import { postRelaunchInvitationRecordFromFolder } from "../Services/folder.service";
import {
  postRelaunchInvitationRecordDisagreedFromCampaign,
  postRelaunchInvitationRecordInProgressFromCampaign,
} from "../../Editor/Services/campaign.service";
import RecordRelaunchLogsDialog from "./RecordRelaunchLogsDialog.vue";

export default {
  name: "RelaunchInvitationRecordDialog",
  components: { RecordRelaunchLogsDialog },
  data() {
    return {
      dialogState: false,
      remindLogsDialogState: false,
      message: "",
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      isRelaunchInvitationDialogOpen: (state) =>
        state.folder.modals.isRelaunchInvitationDialogOpen,
      recordToRelaunch: (state) => state.course.recordToRelaunch,
      activeCampaign: (state) => state.course.activeCampaign,
      offersRecordsCosmoforms: (state) => state.folder.offersRecordsCosmoforms,
    }),
    lastRemindDate() {
      if (!this.recordToRelaunch?.reminders?.length) return;

      return this.$t("utils.lastSentDate", {
        date: this.$d(
          new Date(this.recordToRelaunch?.reminders[0]?.dateCreated),
          "full"
        ),
      });
    },
    recordLogs() {
      if (!this.recordToRelaunch?.reminders?.length) return [];
      return this.recordToRelaunch.reminders.map((record, index) => ({
        ...record,
        actionDisplay: this.$tc(
          "campaign.records.relaunchLogs",
          index === this.recordToRelaunch.reminders.length - 1 ? 0 : 1,
          {
            clientEmail: this.recordToRelaunch?.email,
            assignFromName:
              record.collaborator?.firstname !== "" &&
              record.collaborator?.lastname !== ""
                ? `${record.collaborator?.firstname} ${record.collaborator?.lastname}`
                : record.collaborator?.email,
          }
        ),
      }));
    },
    courseName() {
      if (!this.recordToRelaunch) return "";
      switch (this.$route.name) {
        case "campaignDetail":
        case "folderDetail":
          return this.recordToRelaunch?.courseName;

        case "clientDetail":
          return this.recordToRelaunch?.campaign?.course?.name;

        default:
          return "";
      }
    },
  },
  methods: {
    reset() {
      this.$store.commit("folder/SET_RELAUNCH_DIALOG_OPEN", false);
      this.$store.commit("folder/SET_RECORD_TO_RELAUNCH", null);
      this.message = "";
      this.isSubmitting = false;
    },
    async relaunchRecordDisagreed(params) {
      await postRelaunchInvitationRecordDisagreedFromCampaign(params);
      await this.$store.dispatch(
        "course/fetchOrganizationCourseCampaignRecordsDisagreed",
        {
          organizationId: this.$route.params.organizationId,
          campaignId: this.activeCampaign.id,
        }
      );
    },
    async relaunchRecordInProgress(params) {
      await postRelaunchInvitationRecordInProgressFromCampaign(params);
      await this.$store.dispatch(
        "course/fetchOrganizationCourseCampaignRecordsInProgress",
        {
          organizationId: this.$route.params.organizationId,
          campaignId: this.activeCampaign.id,
        }
      );
    },
    async relaunchInvitation() {
      this.isSubmitting = true;
      const payload = { message: this.message };
      try {
        switch (this.$route.name) {
          case "folderDetail":
            {
              await postRelaunchInvitationRecordFromFolder({
                organizationId: +this.$route.params.organizationId,
                folderId: +this.$route.params.folderId,
                recordId: this.recordToRelaunch?.id,
                payload,
              });
              let response = await this.$store.dispatch(
                "folder/fetchActiveFolderRecord",
                {
                  organizationId: +this.$route.params.organizationId,
                  folderId: +this.$route.params.folderId,
                  recordId: this.recordToRelaunch?.id,
                }
              );
              this.$store.commit(
                "folder/UPDATE_FOLDER_RECORDS_OFFER_COSMOFORM",
                {
                  id: response.id,
                  keyToUpdate: "reminders",
                  value: [...response.reminders],
                  type: "RECORD",
                }
              );
            }
            break;
          case "clientDetail":
            await postRelaunchInvitationRecordFromClient({
              organizationId: +this.$route.params.organizationId,
              clientId: +this.$route.params.clientId,
              recordId: this.recordToRelaunch?.id,
              payload,
            });
            break;
          case "campaignDetail":
            {
              const params = {
                organizationId: +this.$route.params.organizationId,
                courseId: +this.$route.params.courseId,
                recordId: this.recordToRelaunch?.id,
                campaignId: this.$route.params.campaignId,
                payload,
              };
              this.recordToRelaunch.isDisagree
                ? await this.relaunchRecordDisagreed(params)
                : await this.relaunchRecordInProgress(params);
            }
            break;
        }

        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.relaunchSuccess"),
          type: "SUCCESS",
        });
        this.reset();
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.relaunchError"),
          type: "ERROR",
        });
        this.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .item_section {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    .label {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 1em;
      color: #353542;
    }
    ::v-deep {
      .v-input__control .v-input__slot {
        & > fieldset {
          border: 1px solid #b6bdff;
        }
      }
    }
    .desc {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 17px;
      color: #707080;
    }
    .files {
      padding: 16px;
      background: #f9f9f9;
      border-radius: 5px;
      line-height: 20px;
      color: #353542;
      text-decoration: underline;
    }
    .item_footer {
      margin-top: 10px;
      padding: 5px;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
      color: #707080;
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    &:first-child {
      margin: 0;
      margin-right: auto;
    }
    margin-left: 16px;
  }
}
</style>
