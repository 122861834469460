<template>
  <div class="space__client_container">
    <!-- logo organization  -->
    <ClientSpaceItems class="mb-5">
      <template #left_items>
        <div class="text" v-text="$t('organization.logo')" />
        <BaseParagraph
          color="primary"
          v-text="activeOrganization.logo ? 'logo' : ''"
        />
      </template>
      <template #right_items>
        <BaseButton
          type="primary"
          small
          icon="$mdiPencilOutline"
          class="ml-auto"
          @click.prevent="openModal('logo')"
        >
          {{ $t("utils.edit") }}
        </BaseButton>
      </template>
    </ClientSpaceItems>
    <!-- organization description -->
    <ClientSpaceItems class="mb-5">
      <template #left_items>
        <div class="text" v-text="$t('organization.description')" />
        <BaseMarkdownViewer
          :content="truncateDescription(activeOrganization.description)"
          is-text-caption
        />
      </template>
      <template #right_items>
        <BaseButton
          type="primary"
          small
          icon="$mdiPencilOutline"
          class="ml-auto"
          @click.prevent="openModal('description')"
        >
          {{ $t("utils.edit") }}
        </BaseButton>
      </template>
    </ClientSpaceItems>
    <!-- organization location -->
    <ClientSpaceItems class="mb-5">
      <template #left_items>
        <div class="text" v-text="$t('organization.location')" />
        <BaseMarkdownViewer
          :content="truncateDescription(activeOrganization.contact)"
          is-text-caption
        />
      </template>
      <template #right_items>
        <BaseButton
          type="primary"
          small
          icon="$mdiPencilOutline"
          class="ml-auto"
          @click.prevent="openModal('location')"
        >
          {{ $t("utils.edit") }}
        </BaseButton>
      </template>
    </ClientSpaceItems>
    <!-- organization services  -->
    <ClientSpaceItems>
      <template #left_items>
        <div class="text" v-text="$t('organization.services')" />
        <div
          v-for="(service, index) in organizationCampaignsServices"
          :key="service.id + index"
          class="d-flex align-center my-2"
        >
          <BaseParagraph color="primary" v-text="service.name" />
          <BaseButton
            type="primary"
            small
            icon="$mdiClose"
            class="ml-auto"
            @click="deleteCampaignFromCustomerServices(service)"
          >
            {{ $t("utils.delete") }}
          </BaseButton>
        </div>
      </template>
    </ClientSpaceItems>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ClientSpaceItems from "./ClientSpaceItems.vue";
export default {
  name: "ClientSpace",
  components: { ClientSpaceItems },
  computed: {
    ...mapState({
      activeOrganization: (state) => state.organization.activeOrganization,
      organizationCampaignsServices: (state) =>
        state.course.organizationCampaignsServices,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    truncateDescription(description) {
      return description.length > 200
        ? description.substring(0, 175) + "..."
        : description;
    },
    async deleteCampaignFromCustomerServices(campaign) {
      const payload = { published: false };
      try {
        await this.$store.dispatch("course/updateOrganizationCourseCampaign", {
          organizationId: this.$route.params.organizationId,
          campaignId: campaign.id,
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.deletedSuccessFullyFromClientSpace"),
          type: "SUCCESS",
        });

        // filter locally campaign services
        this.$store.commit(
          "course/setCampaignServices",
          this.organizationCampaignsServices.filter(
            (service) => service.id !== campaign.id
          )
        );
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.errorDeletingFromClientSpace"),
          type: "ERROR",
        });
      }
    },
    async initialize() {
      if (!this.$route.params.organizationId) return;
      await this.$store.dispatch("course/getOrganizationCampaignsServices", {
        organizationId: this.$route.params.organizationId,
      });
    },
    openModal(type) {
      this.$store.commit(
        "organization/setIsOrganizationEditInformationModalOpen",
        { status: true, type }
      );
    },
  },
};
</script>
