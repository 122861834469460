import { getFileTypeByName } from "@/core/Utils/file.utils";

export default class OfferFile {
  constructor({ name = "", url = "" } = {}) {
    this.name = name;
    this.url = url;
  }

  get fileType() {
    return getFileTypeByName(this.name);
  }
}
