import { render, staticRenderFns } from "./CampaignDetailRecords.vue?vue&type=template&id=8dc8c62c&scoped=true"
import script from "./CampaignDetailRecords.vue?vue&type=script&lang=js"
export * from "./CampaignDetailRecords.vue?vue&type=script&lang=js"
import style0 from "./CampaignDetailRecords.vue?vue&type=style&index=0&id=8dc8c62c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dc8c62c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
