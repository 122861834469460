import Client from "./Client";
import OfferFile from "./OfferFile";
import User from "./User";
import { OfferClient } from "./OfferClient";
import Cancellation from "./Cancellation";

export default class Offer {
  constructor({
    id = Number.NaN,
    title = "",
    description = "",
    image = null,
    files = {},
    client = null,
    creator = null,
    folderId = Number.NaN,
    dateCreated = null,
    dateUpdated = null,
    cancellation = new Cancellation(),
    status = "",
    offerType = "",
    reasonDecline = "",
    clients = null,
    yousignMembers = [],
    signedFiles = [],
    procedureId = null,
    publishDate = null,
    procedure = null,
    isSequential = false,
  } = {}) {
    this.id = Number.parseInt(id);
    this.title = title;
    this.description = description;
    this.cancellation = cancellation ? new Cancellation(cancellation) : null;
    this.image = image;
    if (files) {
      this.files = {
        originalFiles: files.originalFiles.map((file) => new OfferFile(file)),
        signedFiles: files.signedFiles.map((file) => new OfferFile(file)),
        proof: files.proof.map((file) => new OfferFile(file)),
      };
    }
    this.client = client ? new Client(client) : null;
    this.creator = creator ? new User(creator) : null;
    this.folderId = Number.parseInt(folderId);
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
    this.status = status;
    this.offerType = offerType;
    this.reasonDecline = reasonDecline;
    this.clients = [];
    if (clients) {
      this.clients = Array.isArray(clients)
        ? clients.map((c) => new OfferClient({ ...c }))
        : new OfferClient({ ...clients });
    }
    this.yousignMembers = yousignMembers;
    this.signedFiles = signedFiles;
    this.procedureId = procedureId;
    this.publishDate = publishDate ? new Date(publishDate) : null;
    this.procedure = procedure;
    this.isSequential = isSequential;
  }
}
