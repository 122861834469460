import FolderAPI from "./folder.api";

export function findOrganizationFolders({ organizationId, filters }) {
  return FolderAPI.getOrganizationFolders({
    organizationId,
    filters,
  });
}
export function listOrganizationFolders(OrganizationId, params) {
  return FolderAPI.getAllOrganizationFolders(OrganizationId, params);
}

export function findFolder(organizationId, folderId) {
  return FolderAPI.readFolder(organizationId, folderId);
}
export function postArchiveFolders({ organizationId, payload }) {
  return FolderAPI.postArchiveFolders({ organizationId, payload });
}

export function updateFolder(organizationId, folderId, data) {
  let payload = {};
  if (data.name !== undefined) {
    payload.name = data.name;
  }
  if (data.status !== undefined) {
    payload.status = data.status;
  }
  if (data.primaryRecord !== undefined) {
    payload.primaryRecord = data.primaryRecord;
  }
  if (data.client !== undefined) {
    payload.client = data.client;
  }
  if (data.assign !== undefined) {
    payload.assign = data.assign;
  }

  if (data.state) payload.state = data.state;

  return FolderAPI.updateFolder(organizationId, folderId, payload);
}

export function unarchiveFolder({ organizationId, folderId }) {
  return FolderAPI.postUnarchiveFolder({ organizationId, folderId });
}

export function inviteFolder(organizationId, id, data) {
  return FolderAPI.inviteFolder(organizationId, id, data);
}

export function createFolder(organizationId, data) {
  return FolderAPI.createFolder(organizationId, data);
}

export function setFolderWorkflow(organizationId, folderId, payload) {
  return FolderAPI.setFolderWorkflow(organizationId, folderId, payload);
}

export function fetchOrganizationCampaigns(organizationId, page) {
  return FolderAPI.getOrganizationCampaigns(organizationId, page);
}

export function findFolderRecords({ organizationId, folderId, page }) {
  return FolderAPI.getFolderRecords({ organizationId, folderId, page });
}

export function getFolderCosmoformDetails({ organizationId, folderId }) {
  return FolderAPI.getFolderCosmoformList({ organizationId, folderId });
}

export function postRelaunchCosmoform({
  organizationId,
  folderId,
  cosmoformId,
  payload,
}) {
  return FolderAPI.postRelaunchCosmoform({
    organizationId,
    folderId,
    cosmoformId,
    payload,
  });
}
export function postFolderDataToDPAE({ organizationId, folderId }) {
  return FolderAPI.postFolderDataToDPAE({
    organizationId,
    folderId,
  });
}

export function postInvalidateRecord(
  organizationId,
  folderId,
  recordId,
  payload
) {
  return FolderAPI.postInvalidateRecord(
    organizationId,
    folderId,
    recordId,
    payload
  );
}

export function postValidateRecord({
  organizationId,
  folderId,
  recordId,
  payload,
}) {
  return FolderAPI.postValidateRecord({
    organizationId,
    folderId,
    recordId,
    payload,
  });
}

export function getOrganizationCollaborators(organizationId, page) {
  return FolderAPI.getOrganizationCollaborators(organizationId, page);
}
export function getFolderLogs(organizationId, folderId, allLogs, page) {
  return FolderAPI.getFolderLogs(organizationId, folderId, allLogs, page);
}
export function postRelaunchInvitationRecordFromFolder({
  organizationId,
  folderId,
  recordId,
  payload,
}) {
  return FolderAPI.postRelaunchInvitationRecordFromFolder({
    organizationId,
    folderId,
    recordId,
    payload,
  });
}
export function fetchOrganizationFolderStatusQuestions(
  organizationId,
  folderId,
  statusId,
  page
) {
  return FolderAPI.getOrganizationFolderStatusQuestions(
    organizationId,
    folderId,
    statusId,
    page
  );
}

export function postStatusQuestion(
  organizationId,
  folderId,
  statusId,
  payload
) {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("name", payload.name);
    formData.append("label", payload.label);
    formData.append("order", payload.order);
    formData.append("has_free_option", payload.has_free_option);
    payload.typeQuestion
      ? formData.append("type_question", payload.typeQuestion)
      : null;
    formData.append("read_only", payload.readOnly);
    payload.slug ? formData.append("slug", payload.slug) : null;
    payload.required ? formData.append("required", payload.required) : null;
    payload.helpText ? formData.append("help_text", payload.helpText) : null;
    payload.helpFile ? formData.append("help_file", payload.helpFile) : null;
    payload.category ? formData.append("category", payload.category) : null;

    FolderAPI.postStatusQuestion(organizationId, folderId, statusId, formData)
      .then((r) => {
        if (payload.choices && payload.choices.length) {
          let promises = [];
          payload.choices.forEach((choice) => {
            promises.push(
              FolderAPI.postStatusQuestionOption(
                organizationId,
                folderId,
                statusId,
                r.data.id,
                { name: choice.name, slug: choice.slug, order: choice.order }
              )
            );
          });
          Promise.all(promises)
            .then((responses) => {
              responses.forEach((response) => {
                r.data.choices.push(response.data);
              });
              resolve(r.data);
            })
            .catch((err) => reject(err));
        } else resolve(r.data);
      })
      .catch((e) => reject(e));
  });
}

export async function postStatusDatatypeQuestion(
  organizationId,
  folderId,
  statusId,
  payload
) {
  let formData = new FormData();
  formData.append("name", payload.name);
  formData.append("label", payload.label);
  formData.append("order", payload.order);
  payload.datatype ? formData.append("datatype", payload.datatype) : null;
  formData.append("read_only", payload.readOnly);
  payload.required ? formData.append("required", payload.required) : null;
  payload.helpText ? formData.append("help_text", payload.helpText) : null;
  payload.helpFile ? formData.append("help_file", payload.helpFile) : null;
  payload.category ? formData.append("category", payload.category) : null;

  const res = await FolderAPI.postStatusDatatypeQuestion(
    organizationId,
    folderId,
    statusId,
    formData
  );
  return res.data;
}

export function fetchOrganizationFolderStatusActions({
  organizationId,
  folderId,
  statusId,
  page,
}) {
  return FolderAPI.getOrganizationFolderStatusActions({
    organizationId,
    folderId,
    statusId,
    page,
  });
}
export function getOrganizationFolderOffersRecordsCosmoforms(payload) {
  return FolderAPI.getOrganizationFolderOffersRecordsCosmoforms(payload);
}
getOrganizationFolderOffersRecordsCosmoforms;

export function getDataRecord(organizationId, folderId, recordId) {
  return FolderAPI.getDataRecord(organizationId, folderId, recordId);
}
export function getFolderFiles(organizationId, folderId) {
  return FolderAPI.getFolderFiles(organizationId, folderId);
}

export function setFolderCollaboratorStatus(organizationId, folderId, payload) {
  return FolderAPI.postFolderCollaboratorStatus(
    organizationId,
    folderId,
    payload
  );
}

export function getClientOwnfolder(organizationId, folderId) {
  return FolderAPI.getClientOwnfolder(organizationId, folderId);
}

export function getClientFullData(organizationId, folderId) {
  return FolderAPI.getClientFullData(organizationId, folderId);
}
