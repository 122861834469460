<template>
  <v-navigation-drawer
    :app="true"
    height="100vh"
    permanent
    floating
    class="px-6 py-8 navigation-drawer"
  >
    <v-overlay v-model="overlay" contained />

    <router-link :to="{ name: 'dashboard' }">
      <BaseImage :src="$findStatic('cosmoLogo')" class="mx-10 mt-6 mb-7" />
    </router-link>
    <section class="my-2">
      <div v-if="isLoadingOrganizations">
        <BaseSkeletonLoader type="text" class="mb-4" />
        <BaseSkeletonLoader type="chip" class="mb-4" />
        <BaseSkeletonLoader type="chip" />
      </div>
      <div v-else-if="organizations.length">
        <BaseDivider color="primary" />
        <span
          v-if="organizations.length > 1"
          class="text-caption font-weight-regular text-uppercase primary--text mt-2"
          v-text="$tc('organization.organization', organizations.length)"
        />
        <BaseList>
          <BaseListGroup
            v-for="(organization, index) in organizations"
            :key="index"
            color="primary"
            :value="$route.params.organizationId == organization.id"
            :opened="organizations.length === 1"
            class="drawer"
            :class="{
              'custom__list-group--active':
                $route.params.organizationId == organization.id,
            }"
          >
            <template #activator>
              <BaseListItemContent>
                <BaseListItemTitle>
                  <div
                    class="primary--text sub_title"
                    v-text="organization.name"
                  />
                </BaseListItemTitle>
              </BaseListItemContent>
            </template>
            <BaseListItem
              :to="{
                name: 'organizationDashboard',
                params: { organizationId: organization.id },
              }"
            >
              <BaseListItemContent>
                <BaseListItemTitle>
                  <div
                    class="primary--text text_sm ml-6"
                    v-text="$tc('organization.dashboard', 2)"
                  />
                </BaseListItemTitle>
              </BaseListItemContent>
            </BaseListItem>
            <BaseListItem
              :to="{
                name: 'clientList',
                params: { organizationId: organization.id },
              }"
            >
              <BaseListItemContent>
                <BaseListItemTitle>
                  <div
                    class="primary--text text_sm ml-6"
                    v-text="$tc('organization.client', 2)"
                  />
                </BaseListItemTitle>
              </BaseListItemContent>
            </BaseListItem>
            <BaseListItem
              :to="{
                name: 'campaignList',
                params: { organizationId: organization.id },
              }"
            >
              <BaseListItemContent>
                <BaseListItemTitle>
                  <div
                    class="primary--text text_sm ml-6"
                    v-text="$t('utils.campaign')"
                  />
                </BaseListItemTitle>
              </BaseListItemContent>
            </BaseListItem>
            <BaseListItem
              :to="{
                name: 'folderList',
                params: { organizationId: organization.id },
              }"
            >
              <BaseListItemContent>
                <BaseListItemTitle>
                  <div
                    class="primary--text text_sm ml-6"
                    v-text="$tc('organization.folder', 2)"
                  />
                </BaseListItemTitle>
              </BaseListItemContent>
            </BaseListItem>
          </BaseListGroup>
        </BaseList>
      </div>
    </section>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheDrawer",
  computed: {
    ...mapState({
      organizations: (state) => state.organization.organizations,
      isLoadingOrganizations: (state) =>
        state.organization.isLoadingOrganizations,
      user: (state) => state.account.user,
      overlay: (state) => state.snackbar.overlay,
    }),
  },
  created() {
    this.initializeUser();
  },
  methods: {
    async initializeUser() {
      try {
        await this.$store.dispatch("account/fetchUser");
        if (!this.organizations || this.organizations.length === 0) {
          this.$router.push({
            name: "account",
          });
        }
      } catch (error) {
        this.$router.push({
          name: "Login",
          query: {
            next: this.$route.fullPath,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.navigation-drawer {
  box-shadow: 0px 5px 5px rgba(16, 25, 103, 0.04),
    10px 0px 20px rgba(16, 25, 103, 0.02);
}
.drawer >>> .v-list-group__items {
  background-color: white !important;
}
</style>
